import { extend } from 'vee-validate';

type PasswordValidationParams = {
    target: string;
};

extend('password', {
    params: ['target'],
    validate(value, params) {
        return value === (params as PasswordValidationParams).target;
    },
    message: 'Password confirmation does not match'
});
