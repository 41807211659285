<template>
    <span>
        <slot v-if="authorized"></slot>
        <slot v-else name="fallback"></slot>
    </span>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class Authorized extends Vue {
    get authorized() {
        return this.$store.getters['user/hasActiveSession'];
    }

    created() {
        this.$store.dispatch('user/authorize');
    }
}
</script>
