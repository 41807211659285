import Vue from 'vue';
import Component from 'vue-class-component';

import type { VideoBox } from '@/types/Video';
import type { MediaResource } from '@/types/MediaResource';
import type { CSSProperties } from 'vue/types/jsx';

type FileDimensions = {
    width: number;
    height: number;
};

type Cropped = {
    crop_width: number;
    crop_height: number;
    crop_x: number;
    crop_y: number;
};

@Component
export default class CroppedResource extends Vue {
    calculateCroppedStyle(
        box: VideoBox,
        dimensions: FileDimensions
    ): CSSProperties {
        return (
            this.calculateOverscaled(box, dimensions) ??
            this.calculateCropped(box, dimensions) ??
            this.calculateScaled(box, dimensions) ??
            {}
        );
    }

    calculateOverscaled(
        box: VideoBox,
        dimensions: FileDimensions
    ): CSSProperties | null {
        const resource = box.video_media_box?.media_resource;

        if (this.isCroppedResource(resource)) {
            const { width, height } = dimensions;
            const { crop_width, crop_height, crop_x, crop_y } = resource;
            // recalculate scales if image dimension is less than crop dimension
            const shouldRecalculateScale =
                width < crop_width || height < crop_height;

            const scaleImageToBox = box.width > width || box.height > height;

            if (shouldRecalculateScale && scaleImageToBox) {
                const scaleX = box.width / crop_width;
                const scaleY = box.height / crop_height;

                // scale image dimension to desired image dimensions
                const imageScaleX =
                    width < box.width ? box.width / width : width / box.width;
                const imageScaleY =
                    height < box.height
                        ? box.height / height
                        : height / box.height;
                // scale resultant ratios from above to box dimension
                const imageScale = Math.max(imageScaleX, imageScaleY);

                const boxScaleX = scaleX * imageScale;
                const boxScaleY = scaleY * imageScale;

                const boxWidth = width * boxScaleX;
                const boxHeight = height * boxScaleY;

                return {
                    position: 'absolute',
                    display: 'block',
                    left: `${-1 * crop_x * scaleX}px`,
                    top: `${-1 * crop_y * scaleY}px`,
                    width: `${boxWidth}px`,
                    height: `${boxHeight}px`
                };
            }
        }

        return null;
    }

    calculateCropped(
        box: VideoBox,
        dimensions: FileDimensions
    ): CSSProperties | null {
        const resource = box.video_media_box?.media_resource;

        if (this.isCroppedResource(resource)) {
            const { width, height } = dimensions;
            const { crop_width, crop_height, crop_x, crop_y } = resource;

            if (crop_x > width || crop_y > height) {
                const scaleX = box.width / crop_width;
                const scaleY = box.height / crop_height;
                // crop_x + crop_width = image width and crop_y + crop_height equals image height
                // so, we subtract from image dimension to get crop x,y dimension
                const cropImageDiff = {
                    x: crop_x > width ? width - crop_width : crop_x,
                    y: crop_y > height ? height - crop_height : crop_y
                };

                const boxWidth = width * scaleX;
                const boxHeight = height * scaleY;

                // reset crop starting points
                resource.crop_x = cropImageDiff.x;
                resource.crop_y = cropImageDiff.y;

                // use calculate crop_x and crop_y dimensions
                return {
                    position: 'absolute',
                    display: 'block',
                    left: `${-1 * cropImageDiff.x * scaleX}px`,
                    top: `${-1 * cropImageDiff.y * scaleY}px`,
                    width: `${boxWidth}px`,
                    height: `${boxHeight}px`
                };
            }
        }

        return null;
    }

    calculateScaled(
        box: VideoBox,
        dimensions: FileDimensions
    ): CSSProperties | null {
        const resource = box.video_media_box?.media_resource;

        if (this.isCroppedResource(resource)) {
            const { width, height } = dimensions;
            const { crop_width, crop_height, crop_x, crop_y } = resource;

            const scaleX = box.width / crop_width;
            const scaleY = box.height / crop_height;

            const boxWidth = width * scaleX;
            const boxHeight = height * scaleY;

            return {
                position: 'absolute',
                display: 'block',
                left: `${-1 * crop_x * scaleX}px`,
                top: `${-1 * crop_y * scaleY}px`,
                width: `${boxWidth}px`,
                height: `${boxHeight}px`
            };
        }

        return null;
    }

    isCroppedResource(
        resource?: MediaResource | null
    ): resource is MediaResource & Cropped {
        return Boolean(
            resource &&
                resource.crop_width !== null &&
                resource.crop_height !== null &&
                resource.crop_x !== null &&
                resource.crop_y !== null
        );
    }
}
