import Vue from 'vue';
// import shim before original axios typings, so our declaration keep precedence
import type { VueAxiosCached } from '@/shims-axios-cache-interceptor';

import AxiosInstance from 'axios';
import VueAxios from 'vue-axios';
import { setupCache } from 'axios-cache-interceptor';

import {
    onRequestFulfilled,
    onRequestRejected,
    onResponseFulfilled,
    onResponseRejected
} from '@/utils/Http';

const axiosCached = setupCache(AxiosInstance, {
    // options
    ttl: 5 * 1000, // cache requests for 5 seconds
    interpretHeader: false
});

// ajax flag for backend
axiosCached.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

axiosCached.interceptors.request.use(
    onRequestFulfilled(axiosCached),
    onRequestRejected
);

axiosCached.interceptors.response.use(onResponseFulfilled, onResponseRejected);

Vue.use(VueAxios as VueAxiosCached, axiosCached);
