import { extend } from 'vee-validate';

const quotes = [
    {
        from: '"',
        to: '"'
    },
    {
        from: /\B'/,
        to: /'\B/
    },
    {
        from: `“`,
        to: `”`
    },
    {
        from: `‘`,
        to: `’`
    }
];

extend('matchingQuotes', {
    validate(value) {
        if (!value) {
            return false;
        }

        return quotes.every(({ from, to }) => {
            if (from === to) {
                return (
                    (String(value).match(new RegExp(from, 'g')) || []).length %
                        2 ===
                    0
                );
            } else {
                return (
                    (String(value).match(new RegExp(from, 'g')) || [])
                        .length ===
                    (String(value).match(new RegExp(to, 'g')) || []).length
                );
            }
        });
    }
});
