export interface OptionalOptions {
    font?: string;
    fontSize?: string;
    fontWeight?: string;
    lineHeight?: string;
    width?: string;
    wordBreak?: string;
}

interface Options {
    font: string;
    fontSize: string;
    fontWeight: string;
    lineHeight: string;
    width: string;
    wordBreak: string;
}

interface Size {
    width: number;
    height: number;
}

function createDummyElement(text: string, options: Options): HTMLElement {
    const element = document.createElement('div');

    text.split('\n').forEach(part => {
        const line = document.createElement('div');
        const lineText = document.createTextNode(part);
        line.appendChild(lineText);
        element.appendChild(line);
    });

    element.style.fontFamily = options.font;
    element.style.fontSize = options.fontSize;
    element.style.fontWeight = options.fontWeight;
    element.style.lineHeight = options.lineHeight;
    element.style.position = 'absolute';
    element.style.visibility = 'hidden';
    element.style.left = '-999px';
    element.style.top = '-999px';
    element.style.width = options.width;
    element.style.height = 'auto';
    element.style.wordBreak = options.wordBreak;

    document.body.appendChild(element);

    return element;
}

function destroyElement(element: HTMLElement): void {
    element.parentNode?.removeChild(element);
}

function cleanCache(cacheKey: string) {
    if (cache[cacheKey]) {
        delete cache[cacheKey];
    }
}

const cache: Record<string, Size> = {};

export default function calculateSize(
    text: string,
    options: OptionalOptions = {}
): Size {
    const cacheKey = JSON.stringify({ text, options });

    if (cache[cacheKey]) {
        return cache[cacheKey];
    }
    // prepare options
    options.font = options.font || 'Times';
    options.fontSize = options.fontSize || '16px';
    options.fontWeight = options.fontWeight || 'normal';
    options.lineHeight = options.lineHeight || 'normal';
    options.width = options.width || 'auto';
    options.wordBreak = options.wordBreak || 'normal';

    const element = createDummyElement(text, options as Options);

    cache[cacheKey] = element.getBoundingClientRect();

    destroyElement(element);
    // self cleanup, to free memory
    setTimeout(() => cleanCache(cacheKey), 30000);

    return cache[cacheKey];
}
