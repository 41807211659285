export default function reduceErrors(
    errors: string | string[] | Record<string, string>,
    storage = new Set<string>()
) {
    if (typeof errors === 'string') {
        storage.add(errors);
    } else if (Array.isArray(errors)) {
        errors.forEach(error => reduceErrors(error, storage));
    } else {
        for (const error in errors) {
            reduceErrors(errors[error], storage);
        }
    }
}
