import { extend } from 'vee-validate';
import axios from 'axios';

type AvailabilityValidationParams = {
    url: string;
};

extend('availability', {
    params: ['url'],
    validate(value: string | number, params) {
        if (!value) {
            return true;
        }

        const { url } = params as AvailabilityValidationParams;

        return axios
            .post(url, { subdomain: value })
            .then(({ data }) => {
                return data?.meta?.success || false;
            })
            .catch(() => {
                return false;
            });
    },
    message: '{_field_} {_value_} is not available'
});
