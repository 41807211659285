import { Module } from 'vuex';

import { RootState } from '@/store/State';

export type ModalState = {
    ids: Array<string>;
    rendered: Array<string>;
};

const DESTROY_IN_MS = 500; // let animation to complete

const DefaultModalState: ModalState = {
    ids: [],
    rendered: []
};

const Modal: Module<ModalState, RootState> = {
    namespaced: true,
    state: { ...DefaultModalState },
    actions: {
        open({ commit }, id: string) {
            commit('render', id);
            commit('open', id);
        },

        close({ commit }, id: string) {
            commit('close', id);

            setTimeout(() => {
                commit('destroy', id);
            }, DESTROY_IN_MS);
        }
    },
    mutations: {
        open(state, id: string) {
            state.ids.push(id);
        },

        close(state, id: string) {
            state.ids = state.ids.filter(cid => cid !== id);
        },

        render(state, id: string) {
            state.rendered.push(id);
        },

        destroy(state, id: string) {
            state.rendered = state.rendered.filter(cid => cid !== id);
        }
    },
    getters: {
        open: state => (id: string) => {
            return state.ids.includes(id);
        },

        rendered: state => (id: string) => {
            return state.rendered.includes(id);
        }
    }
};

export default Modal;
