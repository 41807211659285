import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';

@Component({
    computed: {
        ...mapGetters('user', ['isAuthor', 'isAdmin'])
    }
})
export default class Training extends Vue {
    isAuthor!: boolean;
    isAdmin!: boolean;

    get canSeeTrainingLink() {
        return !this.isAuthor || !this.isAdmin;
    }
}
