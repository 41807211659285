import Vue from 'vue';
import Component from 'vue-class-component';

import type { VideoBox } from '@/types/Video';

@Component
export default class MediaModuleSlideCollector extends Vue {
    get boxes(): VideoBox[] {
        return [];
    }

    getVisibleBoxesForSlide(slide: number) {
        return (
            this.boxes
                .filter(
                    box =>
                        !box.video_zoom_box &&
                        !this.isTransitionBox(box) &&
                        this.shouldBoxAppearOnSlide(box, slide)
                )
                // ensure box z-index position, order by box ID, and then by frame position
                .sort((a, b) => a.id - b.id || a.start_frame - b.start_frame)
        );
    }

    isTransitionBox(box: VideoBox) {
        const mediaFile = box.video_media_box?.media_files[0];

        if (mediaFile) {
            // system videos are most likely a transition
            return (
                mediaFile.type === 1 &&
                mediaFile.is_system_media &&
                // transitions don't have a preview
                !mediaFile.thumbnail_filename
            );
        }

        return false;
    }

    shouldBoxAppearOnSlide(box: VideoBox, slide: number) {
        const isExplicitMatch = box.ui_group === slide;

        if (!isExplicitMatch) {
            const frame = this.getFrameForSlide(slide);

            if (frame) {
                return (
                    box.start_frame <= frame.start &&
                    box.start_frame + box.duration >= frame.end
                );
            }
        }

        return isExplicitMatch;
    }

    getFrameForSlide(slide: number) {
        const boxes = this.getAllBoxesForSlide(slide);

        if (!boxes.length) {
            return null;
        }

        return {
            start: boxes[0].start_frame,
            end:
                boxes[boxes.length - 1].start_frame +
                boxes[boxes.length - 1].duration
        };
    }

    getAllBoxesForSlide(slide: number) {
        return this.boxes.filter(box => box.ui_group === slide);
    }
}
