<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="160"
        height="120"
        viewBox="0.011 0 1829.089 789.32"
    >
        <path
            d="M1829.1 787.9h-250.101c-2.3 0-6.5-10.101-7.699-12.2-34.4-62.101-68.801-124.5-103-186.5-36.601 66-72.2 133.1-108.7 199.2-81.9.199-163.101-.4-244.3-.2 9.199-17.2 19-34 28.8-50.9 68.3-118 135.6-237.6 204-355.7-72.7-127-146.5-253-219.601-379.7-.199 0-.699-.8-.199-.7h251.3c30.899 56.2 61 113.1 91.399 169.7 31.601-56.1 62.101-113.3 93.4-169.7h242.9c.199 1-.5 2.1-1 2.9-70.9 124.6-143.5 249.6-214.301 374.4C1670.7 515.1 1750.9 651.6 1829.1 787.9z"
            fill="#000000"
        />
        <path
            d="M1221.9 306.5c6.4 26 10.1 54.6 10.1 84.7 0 93.899-27.199 163.5-65 220.1-6.399 9.601-12.899 18.8-19.899 27.601-20.5 26-45.3 49.699-73 69.399-18.601 13.2-38 25.2-59.5 35.3-42.8 20.1-94.501 35.8-154.101 35.8-93.199 0-162-28.4-217.699-67.4C587.1 672.9 543.7 621.2 514.2 556c-19.8-43.7-32.8-94.1-34.3-155.5-.7-30.4 3.1-60.4 8.6-86.4 14-65.6 41.5-117 76.301-161.3C599.6 108.6 643.7 74 697 47.9c20.9-10.2 44.301-18.9 69.801-25.2 25.5-6.3 53.699-10.6 84.199-10.6h.5c29.801-1.4 61.4 3.4 86.2 8.9 25.8 5.7 49.7 14.4 71 24 66 29.8 115.2 74.4 154.8 130.1 26.1 36.5 45.7 80.3 58.4 131.4zm-300 260.6c2.4-9.6 1.7-21.899 1.7-34.6v-289c0-12.4-1.4-22-5-31-3.3-8.2-8.4-15.7-14.4-21.8-11.7-11.9-29.3-22.6-51.399-20.9-10.7.8-19.601 4.2-27.4 9.1-14.5 9.2-25.5 23.3-30 42-2.3 9.5-2.2 21.6-2.2 34.1v289c0 6.2-.3 12.2.5 17.5 2.101 15.2 8.9 27.4 17.8 36.5 11.5 11.7 29.301 21.5 52.301 19 29.698-3.2 51.499-22.9 58.098-49.9zM490.1 215.8H224.4c-1.1 33-.2 69.5-.5 102.7 71.8.6 144.7.1 217 .2V534c-72.8.2-146.2-.3-218.6.2.1 85.399.5 169.2.5 254.6 0 .3-.101.4-.2.5H1.1c-.4.101-.4-.2-.7-.2C-.1 526.9-.1 263 .301.7c0-.3 0-.7.199-.7H475c.301 0 .4 0 .5.2 5 71.8 9.801 143.7 14.6 215.6z"
            fill="#000000"
        />
    </svg>
</template>

<script>
export default {
    name: 'Fox'
};
</script>
