import { library } from '@fortawesome/fontawesome-svg-core';

import {
    faFacebookF,
    faFacebookSquare,
    faInstagram,
    faLinkedin,
    faXTwitter,
    faSquareXTwitter
} from '@fortawesome/free-brands-svg-icons';

library.add(
    faFacebookF,
    faFacebookSquare,
    faInstagram,
    faLinkedin,
    faXTwitter,
    faSquareXTwitter
);
