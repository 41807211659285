/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnalyticsProvider } from '@/components/ClientCabin/Analytics/Provider';

export default class FacebookPixel implements AnalyticsProvider {
    code: string | number | null = null;

    constructor(code: string | number | null) {
        this.code = code;
    }

    load() {
        if (this.code) {
            (function (f: any, b, e, v) {
                if (f.fbq) {
                    return;
                }
                // eslint-disable-next-line @typescript-eslint/no-extra-parens
                const n: any = (f.fbq = function () {
                    n.callMethod
                        ? n.callMethod.apply(n, arguments) // eslint-disable-line prefer-spread, prefer-rest-params
                        : n.queue.push(arguments); // eslint-disable-line prefer-rest-params
                });
                if (!f._fbq) {
                    f._fbq = n;
                }
                n.push = n;
                n.loaded = !0;
                n.version = '2.0';
                n.queue = [];
                const t: any = b.createElement(e);
                t.async = !0;
                t.src = v;
                const s: any = b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t, s);
            })(
                window,
                document,
                'script',
                'https://connect.facebook.net/en_US/fbevents.js'
            );

            fbq('init', String(this.code));
        }
    }

    track(actionName: string, event?: any) {
        if (this.code) {
            if (event) {
                fbq('track', actionName, event);
            } else {
                fbq('track', actionName);
            }
        }
    }

    trackPage() {
        if (this.code) {
            fbq('track', 'PageView');
        }
    }
}
