/**
 * Helper function to convert HTML Nodes textContent into pure text
 * preserving linebreaks
 */
export default function contentToText(node: ChildNode) {
    let value = '';
    let isOnNewLine = true;

    // Recursive function to navigate childNodes and build linebreaks with text
    (function parseChildNodes(childNodes: NodeListOf<ChildNode>) {
        for (let i = 0; i < childNodes.length; i++) {
            const childNode = childNodes[i];

            if (childNode.nodeName === 'BR') {
                // BRs are always line breaks which means the next loop is on a fresh line
                value += '\n';
                isOnNewLine = true;
                continue;
            }

            // We may or may not need to create a new line
            if (childNode.nodeName === 'DIV' && isOnNewLine === false) {
                // Divs create new lines for themselves if they aren't already on one
                value += '\n';
            }

            // Whether we created a new line or not, we'll use it for this content so the next loop will not be on a fresh line:
            isOnNewLine = false;

            // Add the text content if this is a text node:
            if (childNode.nodeType === 3 && childNode.textContent) {
                value += childNode.textContent;
            }

            // If this node has children, get into them as well:
            parseChildNodes(childNode.childNodes);
        }
        // Parse the child nodes for HTML and newlines:
    })(node.childNodes);

    return value;
}
