import Vue from 'vue';
import Vuex from 'vuex';

import { state } from './State';
import modules from './modules';

Vue.use(Vuex);

export default new Vuex.Store({
    state,
    actions: {
        init() {
            for (const [name, module] of Object.entries(modules)) {
                if (typeof module.actions?.init === 'function') {
                    this.dispatch(`${name}/init`);
                }
            }
        }
    },
    plugins: [store => store.dispatch('init')],
    modules
});
