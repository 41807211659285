type HubSpotParameters = {
    region: string | null;
    portalId: string | null;
    formId: string | null;
};

export const extractor = (code: string) => {
    const extracted: HubSpotParameters = {
        region: null,
        portalId: null,
        formId: null
    };

    const paramsRegex = /forms\.create\(\{([\S\s]+)\}/gm;

    const regionRegex = /region:\s*"(.*)",/gm;
    const portalIdRegex = /portalId:\s*"(.*)",/gm;
    const formIdRegex = /formId:\s*"(.*)"/gm;

    const params = paramsRegex.exec(code);

    if (params) {
        const region = regionRegex.exec(params[1]);

        if (region) {
            extracted.region = region[1] || '';
        }

        const portalId = portalIdRegex.exec(params[1]);

        if (portalId) {
            extracted.portalId = portalId[1] || '';
        }

        const formId = formIdRegex.exec(params[1]);

        if (formId) {
            extracted.formId = formId[1] || '';
        }
    }

    return extracted;
};

export const validator = async (code: string) => {
    if (!code) {
        return false;
    }

    const { region, portalId, formId } = extractor(code);

    return Boolean(region && portalId && formId);
};

export default { validator, extractor };
