import Vue from 'vue';
import Component from 'vue-class-component';

import type {
    PrTemplateContextSuggestion,
    PrTemplateEditorSectionField,
    PrTemplateVariation
} from '@/types/PrTemplate';

import { stripHtml } from '@/utils/helpers';

@Component
export default class TemplateMixin extends Vue {
    form!: Record<string, string | number>;

    contextSentences: string[] = [];

    contextSuggestions: PrTemplateContextSuggestion = {};

    setContextSentences(variations: PrTemplateVariation[]) {
        let lines: string[][] = [];

        variations.forEach(variation => {
            const contentLines = variation.content.split('<p>').map(line => {
                return line.split('.').map(sentence => sentence);
            });

            lines = [...lines, ...contentLines];
        });

        return lines.flat().map(line => stripHtml(line));
    }

    getFilteredAndSortedSentences(
        fieldName: PrTemplateEditorSectionField['name']
    ) {
        const filteredSentences = this.contextSentences.filter(sentence =>
            sentence.includes(`{${fieldName}}`)
        );

        if (filteredSentences.length > 0) {
            filteredSentences.sort((a, b) => {
                const aLength = a.match(/\{(.*?)\}/g)?.length || 0;

                const bLength = b.match(/\{(.*?)\}/g)?.length || 0;

                return aLength - bLength;
            });
        }

        return filteredSentences;
    }

    replaceContentAnswers(
        sentence: string,
        highlight = false,
        currentInput = ''
    ) {
        return sentence
            .split(' ')
            .map(word => {
                if (word.match(/\{(.*?)\}/)) {
                    const input = word.match(/\{(.*?)\}/)?.pop();

                    if (input) {
                        if (this.form[input]) {
                            if (highlight && currentInput === input) {
                                return `<span class="secondary white--text px-1 rounded">${this.form[input]}</span>`;
                            }

                            return this.form[input];
                        }

                        return `{${input}}`;
                    }

                    return word;
                }

                return word;
            })
            .join(' ');
    }

    setFieldContexts() {
        // eslint-disable-next-line no-undef
        const templateFields: NodeListOf<HTMLInputElement> =
            document.querySelectorAll('[data-type="template-field"]');

        const suggestions: PrTemplateContextSuggestion = {};

        if (templateFields.length > 0) {
            templateFields.forEach(field => {
                const fieldSentences = this.getFilteredAndSortedSentences(
                    field.name
                );

                if (fieldSentences.length > 0) {
                    if (field.value === '') {
                        suggestions[field.name] = this.replaceContentAnswers(
                            fieldSentences[0]
                        );
                    } else {
                        suggestions[field.name] = this.replaceContentAnswers(
                            fieldSentences[0],
                            true,
                            field.name
                        );
                    }
                }
            });
        }

        this.contextSuggestions = suggestions;
    }
}
