import Vue from 'vue';
import Component from 'vue-class-component';

import { snakeCase } from '@/utils/helpers';

@Component
export default class StatusMixin extends Vue {
    statusCssClass(status: string): string {
        return snakeCase(status);
    }
}
