import { Module } from 'vuex';

import { RootState } from '@/store/State';

export type LogoState = {
    src: string;
    alt: string;
};

const defaultLogoState: LogoState = {
    src: '',
    alt: ''
};

const Logo: Module<LogoState, RootState> = {
    namespaced: true,

    state: { ...defaultLogoState },

    getters: {
        logo(state) {
            return state;
        }
    },

    actions: {
        update({ commit }, logo: Partial<LogoState>) {
            commit('update', logo);
        }
    },

    mutations: {
        update(state, { src, alt }: Partial<LogoState>) {
            if (src) {
                state.src = src;
            }

            if (alt) {
                state.alt = alt;
            }
        }
    }
};

export default Logo;
