import { extend } from 'vee-validate';
// existing rules that are not available by default
// @see https://vee-validate.logaretm.com/v3/guide/rules.html#rules
import {
    between,
    required,
    regex,
    min_value,
    max_value,
    max,
    min,
    email,
    length,
    numeric,
    alpha_dash
} from 'vee-validate/dist/rules';

extend('between', {
    ...between,
    params: ['min', 'max'],
    message: '{_field_} must be between {min} and {max}'
});

extend('required', {
    ...required,
    message: '{_field_} is required'
});

extend('min', {
    ...min,
    params: ['length'],
    message: '{_field_} must be at least {length} characters'
});

extend('regex', {
    ...regex,
    message: '{_field_} is not valid'
});

extend('min_value', {
    ...min_value,
    params: ['min'],
    message: '{_field_} must be greater than or equal to {min}'
});

extend('max_value', {
    ...max_value,
    params: ['max'],
    message: '{_field_} must be less than or equal to {max}'
});

extend('max', {
    ...max,
    params: ['length'],
    message: '{_field_} length cannot exceed {length} characters'
});

extend('email', {
    ...email,
    message: '{_field_} must be a valid e-mail address'
});

extend('length', {
    ...length,
    params: ['length'],
    message: '{_field_} length should be exactly {length}'
});

extend('numeric', {
    ...numeric,
    message: '{_field_} may only contain numeric characters'
});

extend('alpha_dash', {
    ...alpha_dash,
    message:
        '{_field_} may only contain alphabetic characters, numbers, dashes or underscores'
});
