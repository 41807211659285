import Vue from 'vue';
import dayjs from 'dayjs';

import RelativeTime from 'dayjs/plugin/relativeTime';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import Duration from 'dayjs/plugin/duration';
import Utc from 'dayjs/plugin/utc';
import Timezone from 'dayjs/plugin/timezone';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';

// see https://day.js.org/docs/en/display/format#localized-formats for details
dayjs.extend(LocalizedFormat);
// see https://day.js.org/docs/en/plugin/relative-time
dayjs.extend(RelativeTime);

dayjs.extend(Duration);

dayjs.extend(Utc);

dayjs.extend(Timezone);

dayjs.extend(AdvancedFormat);

dayjs.extend(CustomParseFormat);

Vue.use({
    install(Vue) {
        Object.defineProperties(Vue.prototype, {
            $dayjs: {
                get() {
                    return dayjs;
                }
            },
            $date: {
                get() {
                    return dayjs;
                }
            }
        });

        Vue.dayjs = dayjs;
    }
});

export default dayjs;
