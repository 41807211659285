/**
 * List of all Roles presented
 */
export type Role =
    | 'Visitor' // default role
    | 'Development'
    | 'SuperAdmin'
    | 'Admin'
    | 'Agent'
    | 'Editor'
    | 'Affiliate'
    | 'Customer'
    | 'CustomerPendingAffiliate'
    | 'Reseller'
    | 'UnapprovedAffiliate'
    | 'BannedAffiliate'
    | 'BannedAgent'
    | 'ResellerClient'
    | 'ResellerLead'
    | 'Author'
    | 'UnverifiedCustomer'
    | 'AccountSuspended'
    | 'VideoCustomer'
    | 'AnnouncementEngineCustomer'
    | 'TrainingCourseBuyer'
    | 'CompleteRefundsGetGuaranteedTrainingAccessOnlyAmpifire';

/**
 * List  of all Roles used (defined) in FE
 */
export type DefinedRole = Extract<
    Role,
    | 'Visitor'
    | 'Admin'
    | 'Author'
    | 'Editor'
    | 'Customer'
    | 'Reseller'
    | 'TrainingCourseBuyer'
    | 'Affiliate'
>;

/**
 * Map of Roles grouped by similar permissions
 */
export const RolesMap: Record<DefinedRole, Partial<Role>[]> = {
    Visitor: [
        'AccountSuspended',
        'BannedAgent',
        'BannedAffiliate',
        'ResellerClient',
        'UnapprovedAffiliate',
        'UnverifiedCustomer'
    ],
    Admin: ['Development', 'SuperAdmin', 'Admin', 'Agent'],
    Customer: [
        'Affiliate',
        'AnnouncementEngineCustomer',
        'Customer',
        'CustomerPendingAffiliate',
        'VideoCustomer'
    ],
    TrainingCourseBuyer: [
        'TrainingCourseBuyer',
        'CompleteRefundsGetGuaranteedTrainingAccessOnlyAmpifire'
    ],
    Author: ['Author'],
    Editor: ['Editor'],
    Reseller: ['Reseller'],
    Affiliate: ['Affiliate']
};

export function getMappedRole(role: Role) {
    const mappedRole = (Object.keys(RolesMap) as DefinedRole[]).find(
        mappedRole => {
            return RolesMap[mappedRole].includes(role);
        }
    );

    if (mappedRole) {
        return mappedRole;
    }
}
