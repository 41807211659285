import Vue from 'vue';
import { Module } from 'vuex';

import { RootState } from '@/store/State';

export type DirtyState = {
    bits: string[];
    beforeAppLeave: boolean;
};

type Bit = {
    id: string;
    isDirty: boolean;
};

const DefaultDirtyState: DirtyState = {
    bits: [],
    beforeAppLeave: false
};

function hookWindowUnload(state: DirtyState) {
    // https://stackoverflow.com/a/4651049/1344835
    setTimeout(() => {
        setTimeout(() => {
            Vue.set(state, 'beforeAppLeave', true);
        }, 500);
    }, 1);

    return 'You have unsaved changes. If you leave this page, your changes will be lost.';
}

const DirtyBit: Module<DirtyState, RootState> = {
    namespaced: true,

    state: { ...DefaultDirtyState },

    actions: {
        set({ commit, dispatch, state }, bit: Bit) {
            commit('setDirty', bit);

            if (state.beforeAppLeave && !state.bits.length) {
                dispatch('notification/success', 'All changes saved', {
                    root: true
                });

                Vue.set(state, 'beforeAppLeave', false);
            }
        }
    },

    mutations: {
        setDirty(state, bit: Bit) {
            if (bit.isDirty) {
                if (!state.bits.includes(bit.id)) {
                    Vue.set(state, 'bits', [...state.bits, bit.id]);
                }
            } else {
                if (state.bits.includes(bit.id)) {
                    Vue.set(
                        state,
                        'bits',
                        state.bits.filter(id => id !== bit.id)
                    );
                }
            }

            if (state.bits.length) {
                window.onbeforeunload = () => hookWindowUnload(state);
            } else {
                window.onbeforeunload = null;
            }
        }
    }
};

export default DirtyBit;
