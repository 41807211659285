import { extend } from 'vee-validate';
import findUrls from '@/utils/helpers/findUrls';

type LinkCountParams = {
    html: string;
};

extend('linkCount', {
    params: ['html'],
    validate(value, params) {
        if (!value) {
            return false;
        }

        const { html } = params as LinkCountParams;

        const urls = findUrls(value, html === 'html', true);

        return Boolean(urls.length);
    },
    message: '{_field_} must include a link'
});
