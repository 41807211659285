/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
/**
 * Wait for interval until condition returns truthy
 */
export default async function wait(
    conditionCallback: Function = () => true,
    interval = 50,
    timeout = 10000
) {
    return new Promise((resolve, reject) => {
        const shouldTimeout = !!timeout;
        const endTime = shouldTimeout ? window.performance.now() + timeout : 0;

        (function toExec() {
            const result = conditionCallback();

            if (result) {
                resolve(result);
            } else if (
                (shouldTimeout && window.performance.now() < endTime) ||
                !shouldTimeout
            ) {
                setTimeout(toExec, interval);
            } else {
                reject();
            }
        })();
    });
}
