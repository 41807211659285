type GetResponseParameters = {
    scriptCode: string | null;
    formId: string | null;
    e: string | null;
};

export const extractor = (code: string) => {
    const extracted: GetResponseParameters = {
        scriptCode: null,
        formId: null,
        e: null
    };

    const scriptRegex = /'script',\s'(.*)',/gm;

    const formIdRegex = /form-id="([^"]*)"/gm;
    const eRegex = /\se="([^"]*)"/gm;

    const scriptCode = scriptRegex.exec(code);
    if (scriptCode) {
        extracted.scriptCode = scriptCode[1] || '';
    }

    const formId = formIdRegex.exec(code);
    if (formId) {
        extracted.formId = formId[1] || '';
    }

    const e = eRegex.exec(code);
    if (e) {
        extracted.e = e[1] || '';
    }

    return extracted;
};

export const validator = async (code: string) => {
    if (!code) {
        return false;
    }

    const { scriptCode, formId, e } = extractor(code);

    return Boolean(scriptCode && formId && e);
};

export default { validator, extractor };
