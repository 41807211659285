import { Module } from 'vuex';

import { RootState } from '@/store/State';

export type LoadingState = {
    show: boolean;
};

const DefaultLoadingState: LoadingState = {
    show: false
};

const Loading: Module<LoadingState, RootState> = {
    namespaced: true,

    state: { ...DefaultLoadingState },

    actions: {
        show({ commit }) {
            commit('setShow', true);
        },
        hide({ commit }) {
            commit('setShow', false);
        }
    },

    mutations: {
        setShow(state, value: boolean) {
            state.show = value;
        }
    }
};

export default Loading;
