import store from '@/store';
import { isClientCabin } from '@/utils/helpers';

import type { RouteConfig } from 'vue-router';

import clientCabinRoutes from './routes.clientcabin';

const ampifireRoutes: RouteConfig[] = [
    {
        path: '/',
        name: 'Index',
        redirect: () => {
            if (isClientCabin()) {
                return clientCabinRoutes.index;
            }

            return '/welcome';
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/pages/Login'),
        props: true, // pass in the nextUrl
        meta: { title: 'Login' }
    },
    {
        path: '/welcome',
        name: 'Welcome',
        component: () => import('@/pages/Welcome'),
        meta: { requiresAuth: true, title: 'Welcome' },
        beforeEnter(_to, _from, next) {
            const ability = store.getters['user/ability'];

            if (ability.can('view', 'Welcome')) {
                next();
                return;
            }

            // hotfix for TrainingCourseBuyers
            if (
                ['TrainingCourseBuyer'].includes(store.state?.user?.role || '')
            ) {
                window.location.href = '/training';
            } else {
                next();
            }
        }
    },
    {
        path: '/profile/:section?',
        name: 'Profile',
        component: () => import('@/pages/Profile'),
        children: [
            {
                path: '/profile/:section(companies)/:companyId',
                name: 'CompanyEditor',
                component: () =>
                    import(
                        '@/pages/Profile/sections/Companies/CompanyEditorInline.vue'
                    )
            },
            {
                path: '/profile/:section(whitelabel)/:subsection(portal)',
                name: 'ResellerPortal',
                component: () =>
                    import(
                        '@/pages/Profile/sections/Whitelabel/Reseller/ResellerPortal.vue'
                    )
            }
        ],
        meta: { requiresAuth: true, title: 'Profile Settings' }
    },
    {
        path: '/announcements',
        name: 'Announcements',
        component: () => import('@/pages/Announcements'),
        meta: {
            requiresAuth: true,
            title: 'Amps'
        },
        beforeEnter(_to, _from, next) {
            const ability = store.getters['user/ability'];

            if (ability.can('view', 'Announcements')) {
                next();
            } else {
                if (ability.can('view', 'AuthorRequests')) {
                    next('/author-requests');
                } else {
                    // land
                    next('/');
                }
            }
        }
    },
    {
        path: '/announcements/list',
        name: 'AnnouncementsList',
        component: () => import('@/pages/Announcements/List'),
        meta: {
            requiresAuth: true,
            title() {
                return store.getters['user/isClient'] ? 'Amps' : 'All Amps';
            }
        },
        beforeEnter(_to, _from, next) {
            const ability = store.getters['user/ability'];

            if (ability.can('view', 'Announcements')) {
                next();
            } else {
                if (ability.can('view', 'AuthorRequests')) {
                    next('/author-requests/list');
                } else {
                    // land
                    next('/');
                }
            }
        }
    },
    {
        path: '/announcements/:action(report)/:announcementId',
        name: 'AnnouncementReport',
        component: () => import('@/pages/Announcements/Report'),
        meta: {
            requiresAuth: true,
            title: 'Distribution Report'
        }
    },
    {
        path: '/announcements/:action(edit)/:announcementId',
        component: () => import('@/pages/Announcements/Announcement.vue'),
        meta: { requiresAuth: true },
        children: [
            {
                path: ':module(article)',
                name: 'NewsArticleEdit',
                component: () =>
                    import('@/pages/Announcements/Edit/NewsArticle'),
                meta: {
                    title: 'Edit News Article'
                }
            },
            {
                path: ':module(blog-post)',
                name: 'BlogPostEdit',
                component: () => import('@/pages/Announcements/Edit/BlogPost'),
                meta: {
                    title: 'Edit Blog Post'
                }
            },
            {
                path: ':module(podcast)',
                name: 'PodcastEdit',
                component: () => import('@/pages/Announcements/Edit/Podcast'),
                meta: {
                    title: 'Edit Podcast'
                }
            },
            {
                path: ':module(slideshow)',
                name: 'SlideshowEdit',
                component: () => import('@/pages/Announcements/Edit/Slideshow'),
                meta: {
                    title: 'Edit Slideshow'
                }
            },
            {
                path: ':module(infographic)',
                name: 'InfographicEdit',
                component: () =>
                    import('@/pages/Announcements/Edit/Infographic'),
                meta: {
                    title: 'Edit Infographic'
                }
            },
            {
                path: ':module(video)',
                name: 'VideoEdit',
                component: () => import('@/pages/Announcements/Edit/Video'),
                meta: {
                    title: 'Edit Video'
                }
            },
            {
                path: ':module(video)/from-template',
                name: 'VideoFromTemplate',
                component: () =>
                    import('@/pages/Announcements/Edit/Video/FromTemplate'),
                meta: {
                    title: 'New Video from template'
                }
            }
        ]
    },
    {
        path: '/announcements/:action(review)/:announcementId',
        component: () => import('@/pages/Announcements/Announcement.vue'),
        meta: { requiresAuth: true },
        children: [
            {
                path: ':module(article)',
                name: 'NewsArticleReview',
                component: () =>
                    import('@/pages/Announcements/Review/NewsArticle'),
                meta: {
                    title: 'Review News Article'
                }
            },
            {
                path: ':module(blog-post)',
                name: 'BlogPostReview',
                component: () =>
                    import('@/pages/Announcements/Review/BlogPost'),
                meta: {
                    title: 'Review Blog Post'
                }
            },
            {
                path: ':module(podcast)',
                name: 'PodcastReview',
                component: () => import('@/pages/Announcements/Review/Podcast'),
                meta: {
                    title: 'Review Podcast'
                }
            },
            {
                path: ':module(slideshow)',
                name: 'SlideshowReview',
                component: () =>
                    import('@/pages/Announcements/Review/Slideshow'),
                meta: {
                    title: 'Review Slideshow'
                }
            },
            {
                path: ':module(infographic)',
                name: 'InfographicReview',
                component: () =>
                    import('@/pages/Announcements/Review/Infographic'),
                meta: {
                    title: 'Review Infographic'
                }
            },
            {
                path: ':module(video)',
                name: 'VideoReview',
                component: () => import('@/pages/Announcements/Review/Video'),
                meta: {
                    title: 'Review Video'
                }
            }
        ]
    },
    {
        path: '/announcements/:action(publish)/:announcementId',
        name: 'PublishAmp',
        component: () => import('@/pages/Announcements/Publish'),
        meta: { requiresAuth: true, title: 'Publish Amp' }
    },
    {
        path: '/announcements/:action(publish)/:announcementId/loa',
        name: 'LoA',
        component: () => import('@/pages/Announcements/Publish/Loa/Loa.vue'),
        meta: { requiresAuth: true, title: 'Letter of Authorization' }
    },
    {
        path: '/announcements/:action(publish)/:announcementId/loa/agency',
        name: 'AgencyLoA',
        component: () => import('@/pages/Announcements/Publish/Loa/Agency.vue'),
        meta: { requiresAuth: true, title: 'Agency Letter of Authorization' }
    },
    {
        path: '/announcements/:action(publish)/:announcementId/loa/company/:companyId',
        name: 'LoACompany',
        component: () =>
            import('@/pages/Announcements/Publish/Loa/Company.vue'),
        meta: {
            requiresAuth: true,
            title: 'Letter of Authorization | Company Info'
        }
    },
    {
        path: '/author-requests',
        name: 'AuthorRequests',
        component: () => import('@/pages/AuthorRequests'),
        meta: { requiresAuth: true, title: 'Requests' },
        beforeEnter(_to, _from, next) {
            if (store.getters['user/isClient']) {
                // there is nothing to show to Resellers in index
                next('/author-requests/list');
            } else if (
                ['Author', 'Editor'].includes(store.state?.user?.role || '')
            ) {
                // there is nothing to show to Resellers in index
                next('/author-requests-pool');
            } else {
                next();
            }
        }
    },
    {
        path: '/author-requests/list',
        name: 'AuthorRequestsList',
        component: () => import('@/pages/AuthorRequests/List'),
        meta: { requiresAuth: true, title: 'Author Requests' },
        beforeEnter(_to, _from, next) {
            if (['Author', 'Editor'].includes(store.state?.user?.role || '')) {
                // there is nothing to show to Resellers in index
                next('/author-requests-pool');
            } else {
                next();
            }
        }
    },
    {
        path: '/author-requests-pool',
        name: 'AuthorRequestsPool',
        component: () => import('@/pages/AuthorRequestsPool'),
        meta: { requiresAuth: true, title: 'Requests Pool' }
    },
    {
        path: '/author-requests-pool/editorial',
        name: 'AuthorRequestsEditorial',
        component: () => import('@/pages/AuthorRequestsPool/Editorial'),
        meta: { requiresAuth: true, title: 'Editor Panel' }
    },
    {
        path: '/author-requests-pool/assignments',
        name: 'MyAssignments',
        component: () => import('@/pages/AuthorRequestsPool/Assignments'),
        meta: { requiresAuth: true, title: 'My Assignments' }
    },
    {
        path: '/403',
        name: 'NotAllowed',
        component: () => import('@/pages/NotAllowed'),
        meta: { title: 'Access is not allowed' }
    },
    {
        path: '/404',
        name: 'PageNotFound',
        component: () => import('@/pages/PageNotFound'),
        meta: { title: 'Page Not Found' }
    },
    {
        path: '/announcements/create',
        name: 'AnnouncementsCreate',
        component: () => import('@/pages/Announcements/Create'),
        meta: { requiresAuth: true }
    },
    {
        path: '/author-requests/create/:id?',
        name: 'AuthorRequestsCreate',
        component: () => import('@/pages/AuthorRequests/Create'),
        meta: { requiresAuth: true },
        beforeEnter(_to, _from, next) {
            if (['Author', 'Editor'].includes(store.state?.user?.role || '')) {
                // there is nothing to show to Resellers in index
                next('/author-requests-pool');
            } else {
                next();
            }
        }
    },
    {
        path: '/author-requests/success/:id?',
        name: 'AuthorRequestsSuccess',
        component: () => import('@/pages/AuthorRequests/Success'),
        meta: { requiresAuth: true }
    },
    {
        path: '/author-requests/review/:id?',
        name: 'AuthorRequestsReview',
        component: () => import('@/pages/AuthorRequests/Review'),
        meta: { requiresAuth: true }
    },
    {
        path: '/author_requests/start/:id',
        beforeEnter(to) {
            window.location.href = to.fullPath; // todo: remove this once PROD-1277 is released
        }
    },
    {
        path: '/author-requests/settings/:id',
        name: 'AuthorRequestsSettings',
        component: () => import('@/pages/AuthorRequests/Settings'),
        meta: { requiresAuth: true }
    },
    {
        path: '/credits/convert',
        name: 'ConvertCredits',
        component: () => import('@/pages/Credits'),
        meta: { requiresAuth: true, title: 'Convert PressCable Credits' }
    },
    {
        path: '/credits/purchase',
        name: 'PurchaseCredits',
        component: () => import('@/pages/Credits/Purchase.vue'),
        meta: { requiresAuth: true, title: 'Purchase Credits' },
        children: [
            {
                path: ':product(amp)',
                name: 'AmpCredits',
                component: () => import('@/pages/Credits/Purchase/Amp.vue'),
                meta: {
                    title: 'Add More Campaigns'
                }
            },
            {
                path: ':product(dfy)',
                name: 'DoneForYouCredits',
                component: () =>
                    import('@/pages/Credits/Purchase/DoneForYou.vue'),
                meta: {
                    title: 'Hire Our Content Team To Produce All The Content For You'
                }
            },
            {
                path: ':product(premium)',
                name: 'PremiumCredits',
                component: () => import('@/pages/Credits/Purchase/Premium.vue'),
                meta: {
                    title: 'Add More Campaigns'
                }
            },
            {
                path: ':product(msn)',
                name: 'MSNCredits',
                component: () => import('@/pages/Credits/Purchase/MSN.vue'),
                meta: {
                    title: 'Add More Campaigns'
                }
            },
            {
                path: ':product(financial)',
                name: 'FinancialCredits',
                component: () =>
                    import('@/pages/Credits/Purchase/Financial.vue'),
                meta: {
                    title: 'Add More Campaigns'
                }
            },
            {
                path: ':product(blow)',
                name: 'BLOWCredits',
                component: () => import('@/pages/Credits/Purchase/BLOW.vue'),
                meta: {
                    title: 'Add More Campaigns'
                }
            },
            {
                path: ':product(defi)',
                name: 'CryptoCredits',
                component: () => import('@/pages/Credits/Purchase/Crypto.vue'),
                meta: {
                    title: 'Add More Campaigns'
                }
            }
        ]
    },
    {
        path: '/admin/videos/approval',
        name: 'ApproveVideos',
        component: () => import('@/pages/Admin/Videos/Approval'),
        meta: { requiresAuth: true, title: 'Approve Videos' },
        beforeEnter(_to, _from, next) {
            const ability = store.getters['user/ability'];

            if (ability.can('manage', 'Videos')) {
                next();
            } else {
                // land
                next('/');
            }
        }
    },
    {
        path: '/admin/blog-posts/approval',
        name: 'ApproveBlogPosts',
        component: () => import('@/pages/Admin/BlogPosts/Approval'),
        meta: { requiresAuth: true, title: 'Approve BlogPosts' },
        beforeEnter(_to, _from, next) {
            const ability = store.getters['user/ability'];

            if (ability.can('manage', 'BlogPosts')) {
                next();
            } else {
                // land
                next('/');
            }
        }
    },
    {
        path: '/admin/articles/search',
        name: 'SearchNewsArticles',
        component: () => import('@/pages/Admin/Articles/Search'),
        meta: { requiresAuth: true, title: 'Search News Articles' },
        beforeEnter(_to, _from, next) {
            const ability = store.getters['user/ability'];

            if (ability.can('manage', 'NewsArticles')) {
                next();
            } else {
                // land
                next('/');
            }
        }
    },
    {
        path: '/guidelines/msn',
        name: 'MSNGuidelines',
        component: () =>
            import('@/pages/ContentGuidelines/MSNContentGuidelines'),
        meta: { requiresAuth: true, title: 'MSN Guidelines' }
    },
    // keep that entry the LAST
    { path: '*', redirect: '/404' }
];

export default [...ampifireRoutes, ...clientCabinRoutes.routes];
