<template>
    <v-container v-if="hasMessages">
        <a-alert
            v-for="(message, i) in systemMessages"
            :key="i"
            :message="message.message"
            :type="message.type"
            class="mt-4"
            dismissible
        />
        <slot v-if="!systemMessages.length"></slot>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';

import { AAlert } from '@/components/AAlert';

import type { SystemMessage } from '@/types/SystemMessage';

@Component({
    components: {
        AAlert
    },
    computed: {
        ...mapState('user', ['systemMessages'])
    }
})
export default class SystemMessages extends Vue {
    systemMessages!: SystemMessage[];

    get hasMessages() {
        return !!this.systemMessages.length;
    }
}
</script>
