import type { IconDefinition } from '@fortawesome/fontawesome-common-types';

export default {
    prefix: 'fas',
    iconName: 'canvas-horizontal',
    icon: [
        18,
        18,
        [],
        '',
        'M0.80675 17.5979C0.636356 17.5094 0.495939 17.3869 0.398586 17.2417C0.301233 17.0966 0.250116 16.9335 0.249999 16.7676L0.25 10.8748C0.25 10.6144 0.375696 10.3646 0.599433 10.1804C0.823169 9.99619 1.12662 9.89271 1.44303 9.89271L16.5548 9.89271C16.826 9.89231 17.0892 9.96797 17.3012 10.1072C17.5131 10.2465 17.6611 10.441 17.7208 10.6588C17.7804 10.8766 17.7482 11.1046 17.6293 11.3052C17.5105 11.5059 17.3122 11.6673 17.067 11.7627L1.95525 17.6555C1.77343 17.7264 1.5728 17.7583 1.372 17.7482C1.1712 17.7381 0.976758 17.6864 0.80675 17.5979ZM11.2514 11.857L2.63766 11.857L2.63766 15.2159L11.2514 11.857ZM0.251591 0.904732C0.251596 0.793078 0.286285 0.683279 0.352362 0.585771C0.418438 0.488264 0.513706 0.406286 0.629112 0.347629C0.74452 0.288972 0.87623 0.255586 1.01173 0.250642C1.14723 0.245697 1.28201 0.26936 1.40327 0.319381L17.3104 6.86692C17.4705 6.93299 17.5989 7.0416 17.6747 7.17519C17.7504 7.30878 17.7693 7.45951 17.728 7.60299C17.6868 7.74647 17.588 7.8743 17.4475 7.96577C17.307 8.05725 17.1332 8.10702 16.9541 8.10703L1.04695 8.10703C0.836006 8.10703 0.633703 8.03805 0.484544 7.91526C0.335386 7.79247 0.25159 7.62593 0.25159 7.45227L0.251591 0.904732Z'
    ]
} as unknown as IconDefinition;
