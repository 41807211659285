/* eslint-disable @typescript-eslint/no-explicit-any */
import GoogleAnalytics from './providers/GoogleAnalytics';
import FacebookPixel from './providers/FacebookPixel';
import Segment from './providers/Segment';

import { AnalyticsEvents } from './AnalyticsEvents';

import type { ClientCabinAnalytics } from '@/types/ClientCabin';
import type { AnalyticsProvider } from './Provider';

const Analytics = {
    config: {
        google_analytics: null,
        fb_pixel: null,
        segment_io: null
    } as ClientCabinAnalytics,

    providers: [] as AnalyticsProvider[],

    init(options: ClientCabinAnalytics) {
        this.config = options;

        if (this.config.google_analytics) {
            const ga = new GoogleAnalytics(this.config.google_analytics);
            ga.load();
            this.providers.push(ga);
        }

        if (this.config.fb_pixel) {
            const fp = new FacebookPixel(this.config.fb_pixel);
            fp.load();
            this.providers.push(fp);
        }

        if (this.config.segment_io) {
            const sio = new Segment(this.config.segment_io);
            sio.load();
            this.providers.push(sio);
        }
    },

    trackPage() {
        this.providers.forEach(p =>
            p.trackPage(
                document.title,
                window.location.href,
                window.location.pathname
            )
        );
    },

    track(event: AnalyticsEvents) {
        this.providers.forEach(p => p.track(event));
    }
};

export default Analytics;
