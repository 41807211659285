import Vue from 'vue';
import Component from 'vue-class-component';

import type { FormMediaResource } from '@/types/Media';

@Component
export default class MediaList extends Vue {
    resources!: FormMediaResource[];

    findMediaFileIndex(media: FormMediaResource) {
        return this.resources.findIndex(
            resource => resource.media_file_id === media.media_file_id
        );
    }

    add(resources: FormMediaResource[]) {
        if (this.resources.length > 0) {
            const featured = this.resources.find(resource => resource.featured);

            if (!featured) {
                this.resources[0].featured = true;
            }
        } else {
            resources[0].featured = true;
        }

        this.resources = [...this.resources, ...resources];
    }

    remove(resource: FormMediaResource) {
        const index = this.findMediaFileIndex(resource);

        if (~index) {
            if (this.resources[index].featured && this.resources[index + 1]) {
                this.resources[index + 1].featured = true;
            }

            this.resources.splice(index, 1);
        }
    }

    replace(
        newResource: FormMediaResource,
        resourceToReplace: FormMediaResource
    ) {
        const index = this.findMediaFileIndex(resourceToReplace);

        if (~index) {
            if (resourceToReplace.featured) {
                newResource.featured = true;
            }

            this.resources.splice(index, 1, newResource);
        }
    }

    feature(resource: FormMediaResource) {
        const index = this.findMediaFileIndex(resource);

        if (~index) {
            this.resources.forEach(mediaResource => {
                mediaResource.featured = false;
            });

            this.resources[index].featured = true;
        }
    }

    delete(ids: number[]) {
        const resourcesToRemove = this.resources.filter(resource =>
            ids.includes(resource.media_file_id)
        );

        resourcesToRemove.forEach(resource => this.remove(resource));
    }
}
