export default function stripHtml(value: string, preserveWhiteSpace = false) {
    const dom = new DOMParser().parseFromString(value, 'text/html');

    if (preserveWhiteSpace) {
        const walker = dom.createTreeWalker(dom.body, NodeFilter.SHOW_TEXT);

        let node;
        const textNodes = [];
        // eslint-disable-next-line @typescript-eslint/no-extra-parens
        while ((node = walker.nextNode())) {
            textNodes.push(node);
        }

        textNodes.forEach(node => {
            // ensure every text node ends with whitespace,
            // usefull for words count
            node.nodeValue = `${node.nodeValue} `;
        });
    }

    return dom.body.textContent || '';
}
