import { extend } from 'vee-validate';
import wordCount from '@/utils/helpers/wordCount';

type WordCountBetweenParams = {
    range: string[];
};

extend('wordCountBetween', {
    params: ['range'],
    validate(value, params) {
        if (!value) {
            return false;
        }

        const { range } = params as WordCountBetweenParams;

        if (range.length !== 2) {
            return false;
        }

        const count = value ? wordCount(value) : 0;

        return count >= Number(range[0]) && count <= Number(range[1]);
    }
});
