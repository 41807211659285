/* eslint-disable @typescript-eslint/no-explicit-any */
export default function convertToArray(
    obj: any,
    truncateProperty = 0,
    ellipsis = false
) {
    const items = [];
    for (const key in obj) {
        let value = obj[key];

        if (truncateProperty && value.length > truncateProperty) {
            value =
                value.substring(0, truncateProperty) + (ellipsis ? '...' : '');
        }
        items.push({ id: Number.parseInt(key), name: value });
    }
    return items;
}
