import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class UserId extends Vue {
    get userId() {
        return (
            (Array.isArray(this.$route.query.user_id)
                ? this.$route.query.user_id[0]
                : this.$route.query.user_id) || ''
        );
    }

    get activeUserId() {
        return Number(this.$route.query.user_id) || 0;
    }
}
