<template>
    <v-alert
        :type="type"
        :color="color"
        :dense="dense"
        :icon="false"
        text
        class="amp-alert"
        :class="{
            'text-caption': dense,
            'body-2': !dense
        }"
        :dismissible="dismissible"
    >
        <template #prepend>
            <v-progress-circular
                v-if="loading && !noIcon"
                indeterminate
                :color="color"
                :size="dense ? 18 : 32"
                width="2"
                class="align-self-start pt-1 mr-2"
            />
            <v-icon
                v-else-if="!noIcon"
                :color="color"
                :small="dense"
                left
                class="align-self-start pt-1"
            >
                {{ icon }}
            </v-icon>
        </template>
        <slot>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="message"></span>
        </slot>
    </v-alert>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

type AAlertType = 'info' | 'success' | 'error' | 'warning';

const AAlertProps = Vue.extend({
    name: 'AAlert',
    props: {
        type: {
            type: String as PropType<AAlertType>,
            default(): AAlertType {
                return 'error';
            }
        },
        message: {
            type: String,
            default() {
                return '';
            }
        },
        noIcon: {
            type: Boolean,
            default() {
                return false;
            }
        },
        dense: {
            type: Boolean,
            default() {
                return true;
            }
        },
        dismissible: {
            type: Boolean,
            default() {
                return false;
            }
        },
        loading: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

const ColorMap: Record<AAlertType, string> = {
    info: 'secondary',
    success: 'green',
    error: 'red',
    warning: 'orange'
};

const IconMap: Record<AAlertType, string> = {
    info: 'info-circle',
    success: 'check-circle',
    error: 'exclamation-circle',
    warning: 'exclamation-triangle'
};

@Component
export default class AAlert extends AAlertProps {
    get icon() {
        return IconMap[this.type];
    }

    get color() {
        return ColorMap[this.type];
    }
}
</script>

<style lang="scss" scoped>
.amp-alert::v-deep {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.102) !important;

    * {
        word-break: break-word;
    }

    a {
        color: $secondary-color;

        &:hover {
            color: $primary-color;
        }
    }

    &.text-caption {
        .v-alert__dismissible {
            .v-icon,
            .v-icon__component {
                width: 16px;
                height: 16px;
                font-size: 16px;
            }
        }
    }
}
</style>
