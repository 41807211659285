/**
 * Returns a random v4 UUID of the form xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx,
 * where each x is replaced with a random hexadecimal digit from 0 to f, and y is replaced with a random hexadecimal digit from 8 to b.
 * @see https://gist.github.com/1308368
 * @param {any} delimiter
 */
export default function uuid(delimiter = '-') {
    let a, b;
    for (
        b = '', a = 0;
        a++ < 36;
        b +=
            ~a % 5 | ((a * 3) & 4)
                ? (a ^ 15
                      ? 8 ^ (Math.random() * (a ^ 20 ? 16 : 4))
                      : 4
                  ).toString(16)
                : delimiter
    ); // eslint-disable-line curly
    return b;
}
