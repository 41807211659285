import Vue from 'vue';
import Component from 'vue-class-component';
import { wordCount } from '@/utils/helpers';
import { cleanCharacterCount } from '@/utils/helpers/characterCount';

@Component
export default class WordsCounter extends Vue {
    getWordsCounter(max: number) {
        return (v: string) => `${wordCount(v)} / ${max}`;
    }

    actualWordsCount(text = '', min = 0, max = 0, label = 'The field') {
        if (min) {
            return `${label} must be between ${min} and ${max} words. Current: ${wordCount(
                text
            )}`;
        }

        return `${label} must be ${max} words or fewer. Current: ${wordCount(
            text
        )}`;
    }

    characterCount(text = '') {
        return cleanCharacterCount(text);
    }

    getCharacterCounter(max: number) {
        return (v: string) => `${cleanCharacterCount(v)} / ${max}`;
    }

    actualCharactersCount(text = '', min = 0, max = 0, label = 'The field') {
        if (min) {
            return `${label} must be between ${min} and ${max} characters. Current: ${cleanCharacterCount(
                text
            )}`;
        }

        return `${label} must be ${max} characters or fewer. Current: ${cleanCharacterCount(
            text
        )}`;
    }
}
