import axios from 'axios';
import { Module } from 'vuex';
import { RootState } from '@/store/State';
import { AnalyticsBrowser } from '@segment/analytics-next';

let segmentIoKey = 'rptyhe0d7c';
interface SegmentData {
    first_name: string;
    last_name: string;
    userId: number;
    activation: number[];
    hasPublished: boolean;
    role: string;
    userType: string;
}
interface AnalyticsData {
    firstname: string;
    lastname: string;
    id: number;
    isActivatedThreeMonths: boolean;
    isActivatedSixMonths: boolean;
    isActivatedOneYear: boolean;
    userHasPublishedAmp: boolean;
    isAuthor: boolean;
    isCustomer: boolean;
    isInternalUser: boolean;
    isReseller: boolean;
    isSubscriber: boolean;
    userHasMoreDFYAmps: boolean;
}

if (window.location.href.indexOf('ampifire') > -1) {
    segmentIoKey = 'xA1STVu3EZAewhd02X6bT9XR6bGzr1VY';
} else {
    if (window.location.href.indexOf('clientcabin') > -1) {
        segmentIoKey = 'EWzhf4hhT54Nkt7OrvvUB3Xfja3HDAwY';
    }
}

const segmentPayload = (data: AnalyticsData): SegmentData => {
    const activation: number[] = [];
    if (
        data.isActivatedOneYear ||
        data.isActivatedSixMonths ||
        data.isActivatedThreeMonths
    ) {
        data.isActivatedThreeMonths ? activation.push(3) : false;
        data.isActivatedSixMonths ? activation.push(6) : false;
        data.isActivatedOneYear ? activation.push(12) : false;
    }
    const payload: SegmentData = {
        first_name: data.firstname,
        last_name: data.lastname,
        userId: data.id,
        activation: activation,
        hasPublished: data.userHasPublishedAmp ?? false,
        role: data.isAuthor
            ? 'author'
            : data.isCustomer
              ? 'customer'
              : data.isInternalUser
                ? 'internalUser'
                : data.isReseller
                  ? 'reseller'
                  : data.isSubscriber
                    ? 'subscriber'
                    : 'none',
        userType: data.userHasMoreDFYAmps ? 'DFY' : 'DIY'
    };
    return payload;
};

const Analytics: Module<object, RootState> = {
    namespaced: true,

    getters: {},

    mutations: {},

    actions: {
        async identifyUser() {
            return axios.get('/users/hot_jar_analytics').then(({ data }) => {
                if (data?.data?.id) {
                    const analytics = AnalyticsBrowser.load({
                        writeKey: segmentIoKey
                    });
                    analytics.identify(data.data.id, segmentPayload(data.data));
                }
            });
        }
    }
};

export default Analytics;
