import Vue from 'vue';
import Vuetify from 'vuetify';

import VuetifyIcons from './vuetify.icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import './fontawesome';

import '@/assets/sass/theme.scss';

Vue.use(Vuetify);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);

export default new Vuetify({
    icons: {
        iconfont: 'faSvg',
        component: FontAwesomeIcon,
        // custom icons
        values: VuetifyIcons
    },
    theme: {
        themes: {
            light: {
                primary: '#fe0d66',
                secondary: '#59748a',
                tertiary: '#a6bed2',
                accent: '#8c9eff',
                error: '#ff5252',
                success: '#4caf50',
                info: '#59748a',
                warning: '#ff8a5b'
            }
        }
    }
});
