import User from './User/User';
import Broadcast from './Broadcast/Broadcast';
import CacheKey from './CacheKey/CacheKey';
import DirtyBit from './DirtyBit/DirtyBit';
import Notification from './Notification/Notification';
import Modal from './Modal/Modal';
import Loading from './Loading/Loading';
import Logo from './Logo/Logo';
import Clients from './Clients/Clients';
import Authors from './Authors/Authors';
import Analytics from './Analytics/Analytics';

export default {
    user: User,
    analytics: Analytics,
    broadcast: Broadcast,
    cachekey: CacheKey,
    dirtybit: DirtyBit,
    notification: Notification,
    modal: Modal,
    loading: Loading,
    logo: Logo,
    clients: Clients,
    authors: Authors
};
