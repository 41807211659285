import { Role } from './Role';

import type { SystemMessage } from '@/types/SystemMessage';

export interface UserState {
    id: number;
    role: Role;
    isAuthenticated: boolean;
    isAuthorized: boolean;
    isLoading: boolean;
    firstname: string;
    lastname: string;
    email: string;
    fullUserName: string;
    systemMessages: SystemMessage[];
    businessName: string;
    businessEmail: string;
    isLociCycleBuyer: boolean;
    isAsigoBuyer: boolean;
    isDropServeBuyer: boolean;
    isNewDropServeBuyer: boolean;
    isNewSimplerTrafficBuyer: boolean;
    isBoringMethodBuyer: boolean;
    isPressCableUser: boolean;
    isKamManagedUser: boolean;
    hasPressCableCredits: boolean;
}

export const state: UserState = {
    id: 0,
    role: 'Visitor',
    isAuthenticated: false,
    isAuthorized: false,
    isLoading: false,
    firstname: 'Account',
    lastname: '',
    email: '',
    fullUserName: '',
    systemMessages: [],
    businessName: 'Ampifire',
    businessEmail: 'support@ampifire.com',
    isLociCycleBuyer: false,
    isAsigoBuyer: false,
    isDropServeBuyer: false,
    isNewDropServeBuyer: false,
    isNewSimplerTrafficBuyer: false,
    isBoringMethodBuyer: false,
    isPressCableUser: false,
    isKamManagedUser: false,
    hasPressCableCredits: false
};
