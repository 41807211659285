import { AnalyticsProvider } from '@/components/ClientCabin/Analytics/Provider';

window.dataLayer = window.dataLayer || [];

function gtag(...args: unknown[]) {
    window.dataLayer?.push(...args);
}

export default class GoogleAnalytics implements AnalyticsProvider {
    code: string | number | null = null;

    constructor(code: string | number | null) {
        this.code = code;
    }

    load() {
        if (this.code) {
            gtag({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });

            const script = document.createElement('script');
            script.src = `https://www.googletagmanager.com/gtm.js?id=${this.code}`;
            script.async = true;

            const scripts = document.getElementsByTagName('script')[0];

            scripts.parentNode?.insertBefore(script, scripts);
        }
    }

    track(
        actionName: string,
        event?:
            | Gtag.ControlParams
            | Gtag.EventParams
            | Gtag.CustomParams
            | undefined
    ) {
        if (this.code) {
            gtag('event', actionName, event);
        }
    }

    trackPage(pageTitle: string, pageUrl: string, pagePath: string) {
        if (this.code) {
            gtag('config', String(this.code), {
                page_title: pageTitle,
                page_location: pageUrl,
                page_path: pagePath
            });
        }
    }
}
