import Vue from 'vue';
import Component from 'vue-class-component';

import { uuid } from '@/utils/helpers';
import { InterceptedAxiosError } from '@/utils/Http';

@Component
export default class Endpoint extends Vue {
    isLoading = true;

    isUpdating = false;

    cacheId = '';
    controller: AbortController | null = null;

    endpoint = '/';

    get sourceUrl() {
        return this.endpoint;
    }

    created() {
        this.onCreated();
    }

    mounted() {
        this.onMounted();
    }

    onCreated() {
        // do something
    }

    onMounted() {
        this.load();
    }

    load() {
        this.setLoading();

        return this.update(true).finally(this.setLoading.bind(this, false));
    }

    setLoading(isLoading = true) {
        this.isLoading = isLoading;
    }

    setUpdating(isUpdating = true) {
        this.isUpdating = isUpdating;
    }

    async update(refresh?: boolean) {
        this.setUpdating();

        const fetcher = this.getData(refresh);
        // store cacheId for every update call
        const updateId = this.cacheId;

        return fetcher
            .then(({ data }) => {
                if (data) {
                    return this.onData(data);
                }
            })
            .finally(() => {
                if (this.cacheId === updateId) {
                    // stop updating only if ids match
                    this.setUpdating(false);
                }
            });
    }

    async getData(refresh = false) {
        if (refresh) {
            this.setCacheId();
        }

        this.abort(true);

        return this.$http
            .get(this.sourceUrl, {
                id: this.cacheId,
                signal: this.controller?.signal
            })
            .then(({ data }) => data)
            .catch((error: InterceptedAxiosError) => {
                this.onError(error);

                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }

                return {};
            });
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onData(data: Record<string, unknown>) {
        // do something with data
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onError(error: InterceptedAxiosError) {
        // do something with error
    }

    abort(createNew = false) {
        if (this.controller) {
            this.controller.abort();
            this.controller = null;
        }

        if (createNew) {
            this.controller = new AbortController();
        }
    }

    setCacheId() {
        this.cacheId = this.getCacheId();
    }

    getCacheId() {
        return uuid();
    }
}
