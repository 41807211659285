import { stripHtml } from '@/utils/helpers';

export function cleanCharacterCount(content = ''): number {
    if (!content) {
        return 0;
    }

    const text = stripHtml(content, false);
    let count = 0;
    if (text !== '') {
        count = text.length;
    }

    return count;
}
