import axios from 'axios';
import { extend } from 'vee-validate';
import { findUrls } from '@/utils/helpers';

const ENDPOINT = '/press_releases/validate_links';

let validator: ReturnType<typeof setTimeout> | void = void 0;

async function validateLinks(links: string[]) {
    return axios
        .post(ENDPOINT, { content: links })
        .then(({ data }) => {
            return data?.data?.success || false;
        })
        .catch(() => false);
}

extend('reachable_link', {
    validate(value: string) {
        if (!value) {
            return true;
        }

        const links = findUrls(value);

        if (links.length) {
            return new Promise(resolve => {
                if (validator) {
                    clearTimeout(validator);
                    validator = void 0;
                }

                validator = setTimeout(() => {
                    resolve(validateLinks(links));
                }, 1000);
            });
        } else {
            return true;
        }
    },
    message:
        'Some of the {_field_} links provided could not be reached. Please ensure that all links provided are accessible.'
});
