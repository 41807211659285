<template>
    <v-progress-linear indeterminate color="primary" :active="show" absolute />
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';

@Component({
    computed: {
        ...mapState('loading', ['show'])
    }
})
export default class Loading extends Vue {
    show!: boolean;
}
</script>
