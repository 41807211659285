import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isRendered)?_c(VDialog,{attrs:{"max-width":_vm.maxWidth,"persistent":_vm.persistent,"scrollable":""},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c(VCard,{staticClass:"modal-wrapper"},[_c(VCardTitle,{staticClass:"modal-title"},[_c(VRow,[_c(VCol,{staticClass:"modal-title-column"},[_vm._v(_vm._s(_vm.title))]),(_vm.closable)?_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"icon":"","data-testid":"close-button"},on:{"click":_vm.close}},[_c(VIcon,[_vm._v("times")])],1)],1):_vm._e()],1)],1),_c(VCardText,{staticClass:"modal-body"},[_vm._t("default")],2),(_vm.$slots.actions)?_c(VCardActions,{staticClass:"pb-4",class:{ 'flex-column': _vm.$vuetify.breakpoint.smAndDown }},[_vm._t("actions")],2):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }