import Vue from 'vue';
import Component from 'vue-class-component';

import type { MediaFile } from '@/types/MediaFile';
import type { VideoMediaBox } from '@/types/Video';

export type ImageDetails = {
    type: MediaType;
    filename: string;
    width: number;
    height: number;
};

export type VideoDetails = ImageDetails & {
    url: string;
    preview: string;
};

export enum MediaType {
    Image = 0,
    Video = 1
}

@Component
export default class ResourceFile extends Vue {
    getResourceFile(box: VideoMediaBox): ImageDetails | VideoDetails | null {
        return (
            this.getMediaFileForBox(box) ||
            this.getAvailableMediaFileForBox(box)
        );
    }

    getMediaFileForBox(box: VideoMediaBox) {
        return this.getFileDetails(box.media_resource.media_file);
    }

    getAvailableMediaFileForBox(box: VideoMediaBox) {
        return this.getFileDetails(box.media_files[0]);
    }

    getFileDetails(file?: MediaFile | null) {
        if (file) {
            if (file.type === MediaType.Image) {
                return this.getImageDetails(file);
            }

            if (file.type === MediaType.Video) {
                return this.getVideoDetails(file);
            }
        }

        return null;
    }

    getImageDetails(file: MediaFile): ImageDetails | null {
        const { width, height } = this.getFileDimensions(
            file,
            this.isFullResolution(file)
        );

        if (!width || !height) {
            return null;
        }

        return {
            type: MediaType.Image,
            filename: file.relative_filename || '',
            width,
            height
        };
    }

    getVideoDetails(file: MediaFile): VideoDetails | null {
        const { width, height } = this.getFileDimensions(file, false);

        if (!width || !height) {
            return null;
        }

        return {
            type: MediaType.Video,
            filename: file.relative_filename || '',
            preview: file.preview_image || '',
            width,
            height,
            url: file.original_url || file.relative_filename || ''
        };
    }

    getFileDimensions(file: MediaFile, preferFullRes = true) {
        if (file.dimensions && preferFullRes) {
            return file.dimensions;
        }

        if (file.thumbnail_dimensions) {
            return file.thumbnail_dimensions;
        }

        return { width: 0, height: 0 };
    }

    isFullResolution(file: MediaFile) {
        // stock media pending download won't have filename yet
        return Boolean(file.filename);
    }
}
