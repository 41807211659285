export default function truncate(
    value: string,
    length: number,
    ellipsis = true,
    shorten = true
) {
    if (!value) {
        return '';
    }
    if (value.length <= length) {
        return value;
    }
    if (ellipsis && shorten) {
        length = length - 3;
    }
    return value.substring(0, length) + (ellipsis ? '...' : '');
}
