import { extend } from 'vee-validate';

const FREE_DOMAINS = ['gmail.com', 'hotmail.com', 'yahoo.com'];

extend('no_free_domains', {
    validate(value: string) {
        if (!value) {
            return true;
        }

        return FREE_DOMAINS.every(domain => !value.includes(domain));
    },
    message: '{_field_} must have an email corresponding to the company domain'
});
