import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';

import { Watch } from '@/utils/decorators';

@Component({
    computed: {
        ...mapState('dirtybit', {
            beforeAppLeaveWatcher: 'beforeAppLeave'
        })
    }
})
export default class BeforeAppLeave extends Vue {
    @Watch('beforeAppLeaveWatcher')
    onBeforeAppLeave(isLeaving = false) {
        if (isLeaving) {
            this.beforeAppLeave();
        }
    }

    beforeAppLeave() {
        // do something
    }
}
