import DOMPurify from 'dompurify';

type GoogleFormParameters = {
    src: string | null;
    height: number | null;
};

export const extractor = (code: string) => {
    const extracted: GoogleFormParameters = {
        src: null,
        height: null
    };

    // room for form height to avoid scrollbars
    const heightDelta = 220;

    const sanitized = DOMPurify.sanitize(code, {
        ADD_TAGS: ['iframe'],
        ADD_ATTR: ['allow', 'frameborder', 'src', 'height', 'width']
    });

    if (sanitized) {
        const parser = new DOMParser();

        const form = parser.parseFromString(sanitized, 'text/html');
        const iframe = form.querySelector('iframe');

        if (iframe) {
            extracted.src = iframe.getAttribute('src') || '';

            const height = parseInt(iframe.getAttribute('height') || '') || '';

            if (height) {
                extracted.height = height + heightDelta;
            }
        }
    }

    return extracted;
};

export const validator = async (code: string) => {
    if (!code) {
        return false;
    }

    const { src, height } = extractor(code);

    return Boolean(src && height);
};

export default { validator, extractor };
