import Vue from 'vue';
import Component from 'vue-class-component';
import { hex2rgb } from '@/utils/helpers';

import type { VideoBox } from '@/types/Video';
import type { CSSProperties } from 'vue/types/jsx';

@Component
export default class MediaBox extends Vue {
    getStyleForBox(box: VideoBox, index = 1): CSSProperties {
        return {
            left: `${box.x}px`,
            top: `${box.y}px`,
            height: `${box.height}px`,
            width: `${box.width}px`,
            zIndex: 10 + index,
            // magic
            '--box-text-color': box.video_text_box?.color,
            ...this.getStyleForBorder(box),
            ...this.getStyleForColorBox(box)
        };
    }

    getStyleForBorder(box: VideoBox): CSSProperties {
        let style: CSSProperties = {};
        if (box.border_color && box.border_width) {
            const color = hex2rgb(box.border_color);
            const alpha = box.border_opacity / 100;

            style = {
                outlineColor: `rgba(${color.r},${color.g},${color.b},${alpha})`,
                outlineStyle: 'solid',
                outlineWidth: `${box.border_width}px`,
                outlineOffset: `${box.border_width * -1}px`
            };
        }

        if (box.border_radius) {
            style['border-radius'] = box.border_radius;
        }

        return style;
    }

    getStyleForColorBox(box: VideoBox): CSSProperties {
        if (box.video_color_box) {
            const backdropFilter = box.video_color_box.backdrop_filter || '';
            return {
                background: box.video_color_box.color,
                backdropFilter,
                '-webkit-backdrop-filter': backdropFilter,
                opacity: box.video_color_box.opacity
                    ? box.video_color_box.opacity / 100
                    : 1
            };
        }

        return {};
    }
}
