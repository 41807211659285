<template>
    <client-cabin-layout>
        <router-view />
    </client-cabin-layout>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import ClientCabinLayout from '@/layouts/ClientCabin/ClientCabinLayout.vue';

@Component({
    name: 'Clientcabin',
    components: {
        ClientCabinLayout
    }
})
export default class ClientCabin extends Vue {}
</script>
