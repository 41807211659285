import { extend } from 'vee-validate';
import { compareTwoStrings } from 'string-similarity';

type FieldSimilarityValidationParams = {
    target: string;
    threshold: number;
    targetName: string;
};

extend('field_similarity', {
    params: ['target', 'threshold', 'targetName'],
    validate(value: string, params) {
        if (!value) {
            return true;
        }

        const { target, threshold } = params as FieldSimilarityValidationParams;

        const similarity = Number(
            (compareTwoStrings(value, target) * 100).toFixed()
        );

        return similarity < threshold;
    },
    message: (field, params) => {
        const { targetName } = params as FieldSimilarityValidationParams;

        return `${field} should be different from ${targetName}`;
    }
});
