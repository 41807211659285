import Vue from 'vue';
import Component from 'vue-class-component';

import type {
    VideoTemplate,
    AcceptedMediaResource,
    AcceptedVideoCaption
} from '@/types/Video';
import { MediaResource } from '@/types/MediaResource';

@Component
export default class MediaModuleEndpoint extends Vue {
    collectMediaResources(video_template?: Partial<VideoTemplate>) {
        return video_template?.video_boxes?.reduce(
            (acc, box, idx) => {
                if (box.type === 'video_media_box' && box.video_media_box) {
                    const resource = box.video_media_box.media_resource;

                    const { crop_width, crop_height, crop_x, crop_y } =
                        this.getCropDataForResource(resource);

                    acc[idx] = {
                        video_media_box_id: box.video_media_box.id,
                        media_file_id: resource?.media_file?.id,
                        id: resource?.id,
                        crop_height,
                        crop_width,
                        crop_x,
                        crop_y
                    };
                }

                return acc;
            },
            {} as Record<number, AcceptedMediaResource>
        );
    }

    collectVideoCaptions(video_template?: Partial<VideoTemplate>) {
        return video_template?.video_boxes?.reduce(
            (acc, box, idx) => {
                if (box.type === 'video_text_box' && box.video_text_box) {
                    const textBox = box.video_text_box;

                    acc[idx] = {
                        id: textBox.video_caption.id,
                        text: textBox.video_caption.text,
                        video_text_box_id: textBox.id,
                        voiceover_status: null
                    };
                }

                return acc;
            },
            {} as Record<number, AcceptedVideoCaption>
        );
    }

    getCropDataForResource(resource: MediaResource) {
        let { crop_width, crop_height, crop_x, crop_y } = resource;

        if (
            resource.thumbnail_crop &&
            resource.media_file.is_external_stock &&
            !resource.media_file.filename
        ) {
            // upscale crop data to full-res dimensions
            const scale =
                (resource.media_file?.dimensions?.width || 0) /
                (resource.media_file?.thumbnail_dimensions?.width || 1);

            if (scale) {
                crop_width = Math.round(resource.thumbnail_crop.width * scale);
                crop_height = Math.round(
                    resource.thumbnail_crop.height * scale
                );
                crop_x = Math.round(resource.thumbnail_crop.x * scale);
                crop_y = Math.round(resource.thumbnail_crop.y * scale);
            }
        }

        return { crop_width, crop_height, crop_x, crop_y };
    }
}
