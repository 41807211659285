const REGEXP_SCROLL_PARENT = /^(visible|hidden)/;

export default function getScrollParent(
    el: HTMLElement
): HTMLElement | Element | null {
    if (
        !(el instanceof HTMLElement) ||
        typeof window.getComputedStyle !== 'function'
    ) {
        return null;
    }

    if (
        el.scrollHeight >= el.clientHeight &&
        !REGEXP_SCROLL_PARENT.test(
            window.getComputedStyle(el).overflowY || 'visible'
        )
    ) {
        return el;
    }

    if (el.parentElement !== null) {
        return getScrollParent(el.parentElement as HTMLElement);
    }

    return document.scrollingElement || document.body;
}
