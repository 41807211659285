import dayjs from '@/plugins/dayjs';

export const date = 'll';

export const dateTime = 'LLLL';

export default function formatDate(
    value?: string | number | null,
    format = date
) {
    if (!value) {
        return '';
    }

    if (Number.isInteger(value)) {
        return dayjs.unix(Number(value)).format(format).toString();
    }

    return dayjs(value).format(format);
}

export function formatDateTime(value?: string | number, format = dateTime) {
    return formatDate(value, format);
}

export function getTimezone(value: string, format: string, zone: string) {
    // https://github.com/iamkun/dayjs/issues/1154
    //dayjs.tz(value, format, zone).format('z');

    const locale = 'en-GB';
    const timezone = dayjs.tz.guess();
    const shortTimezone = new Intl.DateTimeFormat(locale, {
        timeZone: timezone,
        timeZoneName: 'short'
    })
        .format(dayjs.tz(value, format, zone).toDate())
        .split(' ')[1];

    return shortTimezone;
}
