import { stripHtml } from '@/utils/helpers';

export default function wordCount(content = ''): number {
    if (!content) {
        return 0;
    }

    let words = 0;

    const text = stripHtml(content, true);

    if (text !== '') {
        const allWords = text.split(/\s+/).filter(Boolean);

        words = allWords.length;
    }

    return words;
}
