import Vue from 'vue';
import Component from 'vue-class-component';

import type { VideoBox } from '@/types/Video';

@Component
export default class MediaModuleMedia extends Vue {
    mediaIsChangeable(box: VideoBox) {
        if (!box.video_media_box || !box.video_media_box.media_files) {
            return false;
        }

        return (
            (box.video_media_box.media_files &&
                box.video_media_box.media_files.length > 1) ||
            box.video_media_box.allow_user_images ||
            box.video_media_box.media_file_ids === null
        );
    }
}
