<template>
    <modal id="support-form-modal" title="Contact Support">
        <support-form :on-submit-success="handleFormSubmitSuccess" />
    </modal>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import SupportForm from './SupportForm.vue';
import { Modal } from '@/components/Modal';

@Component({
    name: 'SupportFormModal',
    components: {
        Modal,
        SupportForm
    }
})
export default class SupportFormModal extends Vue {
    handleFormSubmitSuccess(): void {
        this.$store.dispatch('modal/close', 'support-form-modal');
    }
}
</script>
