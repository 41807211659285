export enum PrEventMessageType {
    CommentApprove = 0,
    CommentReject = 1,
    Comment = 2,
    CommentContinuation = 5,
    Admin = 6
}

export enum PrEventStatus {
    Draft = 0,
    Pending = 1,
    Live = 2,
    Rejected = 3,
    NeedsAuthor = 4,
    AwaitingClient = 5,
    ClientApproved = 6,
    AwaitingPayment = 7
}

export interface PrEvent {
    user_id: number;
    message_type: PrEventMessageType;
    message?: string;
    full_message?: string;
    timestamp: number;
    belongs_to_current_user: boolean;
    status: number;
}
