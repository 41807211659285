import Vue from 'vue';

import 'vanilla-cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';

const config: UserConfig = {
    current_lang: 'en',
    autoclear_cookies: true, // default: false
    //theme_css: '../src/cookieconsent.css',
    cookie_name: 'cc_amp_cookie', // default: 'cc_cookie'
    cookie_expiration: 365, // default: 182

    // auto_language: null,                     // default: null; could also be 'browser' or 'document'
    // autorun: true,                           // default: true
    // delay: 0,                                // default: 0
    // force_consent: false,
    // hide_from_bots: false,                   // default: false
    // remove_cookie_tables: false              // default: false
    // cookie_domain: location.hostname,        // default: current domain
    // cookie_path: "/",                        // default: root
    // cookie_same_site: "Lax",
    // use_rfc_cookie: false,                   // default: false
    revision: 0, // default: 0

    gui_options: {
        consent_modal: {
            layout: 'cloud', // box,cloud,bar
            position: 'bottom center', // bottom,middle,top + left,right,center
            transition: 'slide' // zoom,slide
        }
    },

    languages: {
        en: {
            consent_modal: {
                title: `🍪 We use cookies`,
                description: `This website uses cookies to improve your experience. We'll assume you are ok with this. To learn more, see our <a href="/users/terms#cookies" target="_blank" class="cc-link">cookie policy</a>`,
                primary_btn: {
                    text: 'Accept all',
                    role: 'accept_all' // 'accept_selected' or 'accept_all'
                }
            },
            settings_modal: {
                //save_settings_btn: 'Save settings',
                accept_all_btn: 'Accept all',
                //reject_all_btn: 'Reject all',
                //close_btn_label: 'Close',
                cookie_table_headers: [
                    { col1: 'Name' },
                    { col2: 'Domain' },
                    { col3: 'Expiration' },
                    { col4: 'Description' }
                ],
                blocks: [
                    {
                        title: 'Cookie usage 📢',
                        description: `This website uses cookies to improve your experience. We'll assume you are ok with this. To learn more, see our <a href="/users/terms#cookies" target="_blank" class="cc-link">cookie policy</a>`
                    },
                    {
                        title: 'Strictly necessary cookies',
                        description:
                            'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true // cookie categories with readonly=true are all treated as "necessary cookies"
                        }
                    }
                ]
            }
        }
    }
};

export default Vue.component('CookieConsent', {
    created() {
        const cc = window.initCookieConsent();

        cc.run(config);
    },

    render(h) {
        return h();
    }
});
