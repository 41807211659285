import { extend } from 'vee-validate';
import { FormMediaResource } from '@/types/Media';

type ImageMediaResourceLengthParams = {
    min: number;
};

extend('imageMediaResourceLength', {
    params: ['min'],
    validate(value: FormMediaResource[], params) {
        if (!value || value.length === 0) {
            return false;
        }

        const imageResources = value.filter(
            resource => resource.media_file.type === 0
        );

        const { min } = params as ImageMediaResourceLengthParams;

        return imageResources.length >= min
            ? true
            : `Please provide aleast ${min} images`;
    }
});
