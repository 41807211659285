import Vue, { VueConstructor } from 'vue';
import GoogleMaps from '@/modules/GoogleMaps';

const GoogleMapsPlugin = {
    install(Vue: VueConstructor<Vue>) {
        Vue.prototype.$maps = new GoogleMaps(
            'AIzaSyDBmxx5KRqQce3fXtqfRwtnrDj6PArmkLg'
        );
    }
};

Vue.use(GoogleMapsPlugin);
