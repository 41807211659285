<template>
    <ValidationObserver ref="supportFormObserver" slim>
        <v-form
            id="support-form"
            :disabled="isLoading"
            @submit.prevent="onSubmit"
        >
            <v-row>
                <v-col cols="12" sm="6" class="pb-0">
                    <ValidationProvider
                        v-slot="{ errors }"
                        name="Name"
                        rules="required"
                    >
                        <v-text-field
                            v-model="formData.name"
                            name="name"
                            filled
                            label="Name"
                            :error-messages="errors[0]"
                        />
                    </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="6" class="pb-0">
                    <ValidationProvider
                        v-slot="{ errors }"
                        name="Email"
                        rules="required|email"
                    >
                        <v-text-field
                            v-model="formData.email"
                            name="email"
                            label="Email"
                            filled
                            :error-messages="errors[0]"
                        />
                    </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="6" class="pb-0">
                    <ValidationProvider
                        v-slot="{ errors }"
                        name="Issue"
                        rules="required"
                    >
                        <v-select
                            id="user_reported_issue"
                            v-model="formData.user_reported_issue"
                            :error-messages="errors[0]"
                            name="user_reported_issue"
                            :items="issueOptions"
                            :menu-props="{ auto: true }"
                            item-text="label"
                            item-value="value"
                            label="Issue"
                            filled
                        />
                    </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="6" class="pb-0">
                    <ValidationProvider
                        v-slot="{ errors }"
                        name="Product"
                        rules="required"
                    >
                        <v-select
                            id="product"
                            v-model="formData.product"
                            :error-messages="errors[0]"
                            name="product"
                            :items="productOptions"
                            :menu-props="{ auto: true }"
                            item-text="label"
                            item-value="value"
                            label="Product"
                            filled
                        />
                    </ValidationProvider>
                </v-col>
                <v-col cols="12" class="pb-0">
                    <ValidationProvider
                        v-slot="{ errors }"
                        name="Subject"
                        rules="required"
                    >
                        <v-text-field
                            v-model="formData.subject"
                            name="subject"
                            label="Subject"
                            filled
                            :error-messages="errors[0]"
                        />
                    </ValidationProvider>
                </v-col>
                <v-col cols="12" class="pb-0">
                    <ValidationProvider
                        v-slot="{ errors }"
                        name="Comments"
                        rules="required"
                    >
                        <v-textarea
                            v-model="formData.comments"
                            name="comments"
                            filled
                            label="Comments"
                            :error-messages="errors[0]"
                        />
                    </ValidationProvider>
                </v-col>
                <v-col cols="12">
                    <v-btn
                        type="submit"
                        color="primary"
                        class="float-end"
                        :loading="submitting"
                    >
                        Submit
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </ValidationObserver>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { ValidationObserver } from 'vee-validate';
import { ServerResponse } from '@/types/ServerResponse';

type SupportFormData = {
    name: string;
    email: string;
    subject: string;
    comments: string;
    user_reported_issue: string;
    product: string;
};

const SupportFormProps = Vue.extend({
    props: {
        onSubmitSuccess: {
            type: Function,
            required: false,
            default() {
                return null;
            }
        }
    }
});

@Component
export default class SupportForm extends SupportFormProps {
    $refs!: {
        supportFormObserver: InstanceType<typeof ValidationObserver>;
    };

    formData: SupportFormData = {
        name: [
            this.$store.state.user?.firstname,
            this.$store.state.user?.lastname
        ]
            .filter(Boolean)
            .join(' '),
        email: this.$store.state.user?.email,
        subject: '',
        comments: '',
        user_reported_issue: '',
        product: ''
    };

    submitting = false;

    isLoading = false;

    productOptions = [];
    issueOptions = [];

    created() {
        this.loadOptions();
    }

    loadOptions() {
        this.isLoading = true;
        this.$http
            .get('/users/contact_support')
            .then(({ data }) => {
                this.productOptions = data.data.productOptions;
                this.issueOptions = data.data.issueOptions;
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    onSubmit(): void {
        this.$refs.supportFormObserver.validate().then(success => {
            if (!success) {
                return;
            }

            this.submitting = true;

            this.$http
                .post<ServerResponse<void>>(
                    '/users/contact_support',
                    this.formData
                )
                .then(({ data: { meta } }) => {
                    if (!meta.success) {
                        this.$store.dispatch(
                            'notification/error',
                            meta.message
                        );
                    } else {
                        this.$store.dispatch('notification/show', meta.message);

                        if (this.onSubmitSuccess) {
                            this.onSubmitSuccess();
                        }

                        this.clearFormValues();

                        this.$refs.supportFormObserver.reset();
                    }
                })
                .catch(() => {
                    this.$store.dispatch(
                        'notification/error',
                        'Cannot submit request. Please try again later.'
                    );
                })
                .finally(() => {
                    this.submitting = false;
                });
        });
    }

    clearFormValues(): void {
        this.formData.subject = this.formData.comments = '';
        this.formData.user_reported_issue = '';
        this.formData.product = '';
    }
}
</script>
