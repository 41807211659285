import { VAlert } from 'vuetify/lib/components/VAlert';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VAlert,{staticClass:"amp-alert",class:{
        'text-caption': _vm.dense,
        'body-2': !_vm.dense
    },attrs:{"type":_vm.type,"color":_vm.color,"dense":_vm.dense,"icon":false,"text":"","dismissible":_vm.dismissible},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [(_vm.loading && !_vm.noIcon)?_c(VProgressCircular,{staticClass:"align-self-start pt-1 mr-2",attrs:{"indeterminate":"","color":_vm.color,"size":_vm.dense ? 18 : 32,"width":"2"}}):(!_vm.noIcon)?_c(VIcon,{staticClass:"align-self-start pt-1",attrs:{"color":_vm.color,"small":_vm.dense,"left":""}},[_vm._v(" "+_vm._s(_vm.icon)+" ")]):_vm._e()]},proxy:true}])},[_vm._t("default",function(){return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.message)}})]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }