import Vue from 'vue';
import Component from 'vue-class-component';

import type { VideoBox } from '@/types/Video';

export interface MediaBoxActionOptions {
    active: boolean;
    e: Partial<PointerEvent>;
    box: VideoBox | null;
}

@Component
export default class MediaBoxActions extends Vue {
    actionOptions: MediaBoxActionOptions = {
        active: false,
        box: null,
        e: {
            clientX: 0,
            clientY: 0
        }
    };

    onBoxAction(e: PointerEvent, box: VideoBox) {
        const { top, left, width, height } = this.getContainerBoxRect();

        const clientX = e.clientX - left;
        const clientY = e.clientY - top;

        this.actionOptions = {
            active: true,
            box,
            e: {
                clientX,
                clientY,
                width,
                height
            }
        };
    }

    getContainerBoxRect() {
        return { top: 0, left: 0, width: 0, height: 0 };
    }
}
