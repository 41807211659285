<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1000px"
        height="647.281px"
        viewBox="-305 -223.641 1000 647.281"
    >
        <g>
            <polygon
                points="-301.736,117.869 -217.373,117.869 -98.38,314.543 -97.643,314.543 -97.643,123.605 -130.536,123.605
		-130.536,117.869 -59.014,117.869 -59.014,123.605 -91.906,123.605 -91.906,412.067 -101.59,412.067 -262.424,147.186
		-263.107,147.186 -263.107,406.274 -230.266,406.274 -230.266,412.067 -301.736,412.067 -301.736,406.274 -268.844,406.274
		-268.844,123.605 -301.736,123.605 	"
            />
            <path
                d="M-64.382,117.869h213.728v70.679h-4.631c-5-18.209-20.368-64.892-67.577-64.892H20.299v127.677h19.999
		c34.997,0,47.155-24.361,53.944-61.571h4.685V318.28h-4.685c-6.419-32.209-17.104-61.155-49.997-61.155H20.299v149.149h58.63
		c43.943,0,70.784-36.784,80.416-84.73h4.631v90.523H-64.434v-5.793h32.893V123.605h-32.841V117.869z"
            />
            <polygon
                points="147.555,117.869 257.286,117.869 257.286,123.605 231.183,123.605 295.498,341.382 296.229,341.382
		333.758,218.233 305.916,123.605 286.602,123.605 286.602,117.869 393.438,117.869 393.438,123.605 360.227,123.605
		424.912,341.804 425.646,341.804 491.747,123.605 461.012,123.605 461.012,117.869 526.798,117.869 526.798,123.605
		497.854,123.605 405.963,423.641 395.596,423.641 336.965,227.758 336.23,227.758 276.235,423.641 265.864,423.641
		177.187,123.605 147.555,123.605 	"
            />
            <path
                d="M-1.228-72.068c0,83.996-66.574,152.254-151.727,152.254C-237.688,80.186-305,11.874-305-72.068
		c0-83.26,67.364-151.573,152.046-151.573C-67.802-223.641-1.228-155.328-1.228-72.068 M-294.264-72.068
		c21.474,44.576,71.787,87.994,141.31,87.994c69.206,0,119.888-43.47,141.36-87.994c-21.473-43.84-72.154-87.313-141.36-87.313
		C-222.477-159.381-272.79-115.908-294.264-72.068 M-75.643-72.122c0,42.681-34.474,76.679-77.312,76.679
		c-42.895,0-77.682-33.998-77.682-76.679c0-41.998,34.787-76.312,77.682-76.312C-110.116-148.434-75.643-114.12-75.643-72.122"
            />
            <path
                d="M-152.903,79.608c-83.522,0-151.465-68.05-151.465-151.676c0-83.26,67.942-150.995,151.465-150.995
		c83.313,0,151.151,67.734,151.151,150.995C-1.806,12.979-68.17,79.608-152.903,79.608z M-152.903-159.959
		c-73.048,0-122.046,47.207-141.833,87.627c19.631,41.263,68.574,88.836,141.833,88.836c73.313,0,122.258-47.574,141.888-88.31
		C-30.803-112.752-79.854-159.959-152.903-159.959z M-152.954,3.979c-42.524,0-77.104-34.155-77.104-76.101
		c0-41.788,34.579-75.733,77.104-75.733c42.313,0,76.784,33.998,76.784,75.733C-76.224-30.175-110.641,3.979-152.954,3.979z"
            />
            <path
                d="M140.925-219.693c-24.472,0-55.261,16.264-78.417,41.471c-20.211,22.052-44.313,60.629-44.313,119.258
		c0,75.839,61.995,134.625,115.308,134.625c21.474,0,34.158-5.739,45.315-10.842l0.263-0.105c8.736-3.947,16.315-7.367,26.578-7.367
		c8.687,0,16.894,4.893,21.895,13.052l2.21,3.631V-42.017h-3.212l-0.211,1.051C219.29,3.664,189.974,70.133,138.137,70.133
		c-51.367,0-61.894-50.365-61.894-125.939c0-73.735,7.738-158.414,67.945-158.414c53.628,0,76.571,79.1,82.363,103.31l0.21,0.894
		h3.156v-105.677l-2.158,3.156c-5.207,7.527-12.577,9.054-22.731,9.054c-6.791,0-13.949-2.842-22.157-6.106l-0.37-0.157
		C170.976-214.376,157.976-219.693,140.925-219.693z"
            />
            <path
                d="M417.753-82.226c17.315-4.841,58.257-18.474,58.257-62.682c0-21.681-10.68-39.944-30.786-52.786
		c-18.42-11.841-44.154-18.314-72.417-18.314H242.13v5.684h45.419V66.345H242.13v5.736h136.989
		c64.471,0,107.786-33.839,107.786-84.152C486.905-46.596,464.276-69.544,417.753-82.226 M383.014-85.123h-37.313v-125.204h11.267
		c50.47,0,66.208,9.42,66.208,60.156c0,54.366-13.527,64.313-40.109,64.313 M345.701-79.438h31.002
		c27.944,0,55.261,6.157,55.261,69.788c0,63.943-17.474,75.944-56.685,75.944h-29.578V-79.438z"
            />
            <path
                d="M534.271,58.607c9.21,0,17.156,3.053,26.365,6.579l0.792,0.315c12.365,4.791,26.263,10.159,47.155,10.159
		c39.158,0,86.416-42.63,86.416-95.521c0-55.526-41.471-69.313-81.574-82.68c-34.842-11.631-67.683-22.578-67.683-60.999
		c0-15.21,4.584-50.627,46.738-50.627c51.94,0,76.574,82.047,76.574,102.31v1.211h3.367l0.106-107.785l-2.215,2.894
		c-6.208,8.262-18.625,8.894-22.311,8.894c-0.369,0-0.684,0-0.95,0h-0.365c-4.683,0-12.104-2.526-18.055-4.736
		c-9.89-3.685-22.203-8.262-36.152-8.262c-48.368,0-78.316,48.312-78.316,93.1c0,54.574,41.209,69.417,81.103,83.785
		c33.788,12.104,65.735,23.526,65.735,59.575c0,36.629-27.58,53.051-54.942,53.051c-66.681,0-91.053-101.889-91.053-110.47v-0.629
		h-3.31V71.187l2.103-2.472C521.588,59.345,527.431,58.607,534.271,58.607z"
            />
            <path
                d="M532.904,399.804c9.313,0,17.314,3.155,26.577,6.732l0.791,0.319c12.474,4.893,26.474,10.474,47.577,10.474
		c39.471,0,87.15-43.789,87.15-98.155c0-57.048-41.84-71.259-82.311-84.945c-35.103-11.944-68.262-23.205-68.262-62.677
		c0-15.629,4.584-52.048,47.108-52.048c52.413,0,77.258,84.309,77.258,105.093v1.265h3.367l0.106-110.727l-2.209,2.945
		c-6.266,8.475-18.791,9.153-22.532,9.153c-0.417,0-0.729,0-0.946,0h-0.422c-4.732,0-12.205-2.575-18.203-4.893
		c-10.002-3.788-22.367-8.471-36.476-8.471c-48.79,0-78.994,49.627-78.994,95.627c0,56.052,41.573,71.311,81.784,86.047
		c34.107,12.422,66.259,24.206,66.259,61.206c0,37.632-27.84,54.525-55.362,54.525c-67.261,0-91.844-104.682-91.844-113.471v-0.628
		h-3.361v115.466l2.103-2.58C520.066,400.59,526.007,399.804,532.904,399.804z"
            />
        </g>
    </svg>
</template>

<script>
export default {
    name: 'CbsNews'
};
</script>
