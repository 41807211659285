import Component from 'vue-class-component';
import List from './List';

@Component
export default class ListPaged extends List {
    total = 0;

    perPage = 10;

    get queryParams() {
        return {
            limit: this.perPage,
            page: 1
        };
    }

    onData(data: { length: number }) {
        this.total = data.length;
    }
}
