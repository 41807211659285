import { extend } from 'vee-validate';

const SPECIAL_CHARS = ['<', '>'];

extend('youtube_video_title', {
    validate(value: string) {
        if (!value) {
            return true;
        }

        return SPECIAL_CHARS.every(char => !value.includes(char));
    },
    message: `{_field_} should not contain special chars ${SPECIAL_CHARS.join(
        ', '
    )}`
});
