import { find } from 'linkifyjs';
import { stripHtml } from '../helpers';

const VALID_PROTOCOLS = ['http:', 'https:'];

function findURLsInHTML(html: string) {
    const links: string[] = [];

    const dom = new DOMParser().parseFromString(html, 'text/html');
    const anchors = dom.querySelectorAll('a');

    for (const anchor of anchors.values()) {
        const link = anchor.getAttribute('href');

        if (link) {
            links.push(link);
        }
    }

    return links;
}

function findURLsInText(text: string) {
    return find(stripHtml(text), 'url').map(link => link.value);
}

function isValidUrl(value: string) {
    try {
        const url = new URL(value);
        return Boolean(url) && VALID_PROTOCOLS.includes(url.protocol);
    } catch {
        return false;
    }
}

export default function findUrls(
    value: string,
    isHtml = false,
    filterOutInvalid = false
): string[] {
    let links: string[] = [];

    if (isHtml) {
        links = findURLsInHTML(value);
    } else {
        links = findURLsInText(value);
    }

    if (filterOutInvalid) {
        links = links.filter(url => isValidUrl(url));
    }

    if (links.length) {
        return [...new Set(links)];
    }

    return links;
}
