<template>
    <validation-observer ref="observer" v-slot="instance" slim>
        <!-- We use hacky :ref here to react on vee-validate property -->
        <v-form
            :ref="setDirty(instance.dirty)"
            :disabled="disabled"
            @submit.prevent="handleSubmit"
        >
            <slot v-bind="instance" :ref="$refs.observer"></slot>
        </v-form>
    </validation-observer>
</template>

<script lang="ts">
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';

import { BeforeAppLeave } from '@/mixins';

import { ValidationObserver } from 'vee-validate';

const FormProps = Vue.extend({
    name: 'AForm',
    props: {
        submit: {
            type: Function,
            default() {
                return () => {
                    /* empty */
                };
            }
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            }
        },
        focusOnError: {
            type: Boolean,
            default() {
                return false;
            }
        },
        autoSubmit: {
            type: [Boolean, Number, String],
            default() {
                return false;
            }
        },
        warnDirty: {
            type: Boolean,
            default() {
                return true;
            }
        }
    }
});

@Component({
    components: {
        ValidationObserver
    }
})
export default class AForm extends mixins(FormProps, BeforeAppLeave) {
    _uid!: number;

    $refs!: {
        observer: InstanceType<typeof ValidationObserver>;
    };

    autoSubmitIn = 30; // seconds;

    autoSubmitter: ReturnType<typeof setInterval> | null = null;

    get errors() {
        return this.$refs.observer.errors;
    }

    get fields() {
        return this.$refs.observer.fields;
    }

    get inputs() {
        return this.$refs.observer.refs;
    }

    get isDirty() {
        return this.$refs.observer.flags.dirty;
    }

    get isValid() {
        return this.$refs.observer.flags.valid;
    }

    get isValidated() {
        return this.$refs.observer.flags.validated;
    }

    get hasChanged() {
        return this.$refs.observer.flags.changed;
    }

    mounted() {
        this.handleAutoSubmit();
    }

    beforeDestroy() {
        if (this.autoSubmitter) {
            clearInterval(this.autoSubmitter);
        }

        this.setDirty(false);
    }

    async handleSubmit() {
        await this.$refs.observer.handleSubmit(this.submit);

        return this.onAfterSubmit();
    }

    onAfterSubmit() {
        this.handleFocusOnError();

        return this.resetOnSubmit();
    }

    handleFocusOnError() {
        if (this.$refs.observer?.flags.invalid && this.focusOnError) {
            this.scrollToFirstInvalidControl();
        }
    }

    scrollToFirstInvalidControl() {
        const firstInvalidControl = Object.keys(this.errors).find(
            name => this.errors[name].length
        );

        if (firstInvalidControl) {
            const input = this.inputs[firstInvalidControl];

            if (input) {
                this.$vuetify.goTo(input, {
                    offset: 20
                });
            }
        }
    }

    reset(andValidate = true) {
        if (this.isDirty) {
            // will wait until all providers reset
            return new Promise(resolve => {
                const unwatch = this.$watch(
                    () => this.isDirty,
                    isDirty => {
                        if (!isDirty) {
                            unwatch();

                            resolve(true);
                        }
                    }
                );

                this.$refs.observer.reset();

                if (andValidate) {
                    this.validate();
                }
            });
        }

        return Promise.resolve(true);
    }

    async validate() {
        return this.$refs.observer.validate();
    }

    handleAutoSubmit() {
        if (this.autoSubmit) {
            this.setAutoSubmitInterval();

            this.autoSubmitter = setInterval(
                this.onAutoSubmit.bind(this),
                this.autoSubmitIn * 1000
            );
        }
    }

    setAutoSubmitInterval() {
        if (['string', 'number'].includes(typeof this.autoSubmit)) {
            const interval = Number(this.autoSubmit);

            if (!isNaN(interval)) {
                this.autoSubmitIn = interval;
            }
        }
    }

    onAutoSubmit() {
        if (this.isDirty) {
            // we have to reset current state so we can detect later changes
            this.reset();

            this.$emit('auto-submit');
        }
    }

    setDirty(isDirty: boolean) {
        if (this.warnDirty) {
            this.$store?.dispatch('dirtybit/set', {
                id: this._uid,
                isDirty
            });
        }
    }

    resetOnSubmit() {
        if (this.$refs.observer && !this.$refs.observer.flags.invalid) {
            return this.reset();
        }
    }

    beforeAppLeave() {
        if (this.autoSubmit) {
            this.onAutoSubmit();
        }
    }
}
</script>
