import { InjectReactive } from '@/utils/decorators';
import Vue from 'vue';
import Component from 'vue-class-component';

import { AppConnectionCookie, DistributionSites } from '@/types/Company';

@Component
export default class ConnectApp extends Vue {
    id = '';
    isLinking = false;

    @InjectReactive({
        from: 'onBeforeLeave',
        default() {
            return async () => void 0;
        }
    })
    onBeforeLeave!: () => Promise<void>;

    link() {
        this.isLinking = true;

        this.onLink();
    }

    onLink() {}

    async authorize(url: string, site: DistributionSites) {
        await this.onBeforeLeave();

        this.$cookies.set(
            AppConnectionCookie,
            {
                site
            },
            0 // session cookie
        );
        // redirect to authorization URL
        window.location.href = url;
    }

    cancel() {
        this.close();

        this.$emit('cancelled');
    }

    close() {
        this.reset();

        this.$store.dispatch('modal/close', this.id);
    }

    reset() {
        this.isLinking = false;
    }
}
