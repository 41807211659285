import { extend } from 'vee-validate';

type MaxItemsValidationParams = {
    max: string;
};

extend('max_items', {
    params: ['max'],
    validate(value: string[] | number[], params) {
        if (!value.length) {
            return true;
        }

        const { max } = params as MaxItemsValidationParams;

        return value.length <= Number(max);
    },
    message: '{_field_} count cannot exceed {max} items'
});
