import Vue from 'vue';
import { Module } from 'vuex';

import { RootState } from '@/store/State';

type BroadcastType = 'error' | 'alert' | 'success' | 'info';

type BroadcastEntry = {
    message: string;
    type: BroadcastType;
};

export type BroadcastState = {
    sources: Record<string, BroadcastEntry[]>;
};

const defaultState: BroadcastState = {
    sources: {}
};

const Broadcast: Module<BroadcastState, RootState> = {
    namespaced: true,

    state: { ...defaultState },

    getters: {
        subscribe: state => (source: string) => {
            return state.sources[source] || [];
        }
    },

    actions: {
        error({ commit }, { source, message, clean }) {
            commit('broadcast', { source, message, type: 'error', clean });
        },

        alert({ commit }, { source, message, clean }) {
            commit('broadcast', { source, message, type: 'alert', clean });
        },

        success({ commit }, { source, message, clean }) {
            commit('broadcast', { source, message, type: 'success', clean });
        },

        reset({ commit }, source) {
            commit('reset', source);
        },

        broadcast({ commit }, { source, message, clean }) {
            commit('broadcast', { source, message, type: 'info', clean });
        }
    },

    mutations: {
        broadcast(
            state,
            {
                source,
                message,
                type,
                clean
            }: BroadcastEntry & { source: string; clean?: boolean }
        ) {
            if (!(source in state.sources) || clean) {
                Vue.set(state.sources, source, [{ type, message }]);
            } else {
                state.sources[source].push({ type, message });
            }
        },

        reset(state, source: string) {
            Vue.set(state.sources, source, []);
        }
    }
};

export default Broadcast;
