import {
    Ability,
    AbilityBuilder,
    AbilityClass,
    InferSubjects,
    RawRuleOf
} from '@casl/ability';

import { Action } from './Action';
import { Subject, User } from './Subject';
import { DefinedRole, getMappedRole } from './Role';
import { RolePermissions } from './RolePermissions';

export type AppAbility = Ability<[Action, InferSubjects<Subject> | 'all']>;

export function getRulesFor(user: User): RawRuleOf<AppAbility>[] {
    const builder = new AbilityBuilder(Ability as AbilityClass<AppAbility>);

    if (typeof RolePermissions[user.role as DefinedRole] === 'function') {
        RolePermissions[user.role as DefinedRole](user, builder);
    } else {
        const mappedRole = getMappedRole(user.role);

        if (mappedRole) {
            RolePermissions[mappedRole](user, builder);
        } else {
            throw new Error(`Trying to use unknown role "${user.role}"`);
        }
    }

    return builder.rules;
}
