<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="160"
        height="160"
        viewBox="0 0 192.756 192.756"
    >
        <g>
            <path
                d="M22.527 109.93c-10.876 0-19.693-8.807-19.693-19.67s8.817-19.671 19.693-19.671c3.972 0 7.67 1.175 10.764 3.196L86.94 109.93H22.527zM27.97 65.666c-9.111-5.914-11.701-18.09-5.784-27.197 5.917-9.106 18.1-11.695 27.21-5.781a19.568 19.568 0 0 1 7.276 8.531l27.417 62.497-56.119-38.05zM170.182 109.949c10.902 0 19.74-8.815 19.74-19.69s-8.838-19.69-19.74-19.69c-3.98 0-7.686 1.176-10.787 3.199l-53.773 36.182h64.56v-.001zM164.74 65.665c9.131-5.919 11.727-18.108 5.797-27.223s-18.139-11.706-27.27-5.786a19.586 19.586 0 0 0-7.293 8.54l-27.479 62.556 56.245-38.087zM104.18 28.656c-1.406 1.089-5.26 1.681-4.424 8.543l8.324 58.217 27.762-63.46c4.438-9.938-.045-21.581-10.01-26.005-9.967-4.425-21.645.044-26.082 9.982a19.567 19.567 0 0 0-1.584 10.241l5.232-.009c2.965.147 4.526.935.782 2.491zM56.853 31.972c-4.428-9.929.045-21.562 9.99-25.982 9.946-4.42 21.599.045 26.027 9.974a19.562 19.562 0 0 1 1.458 11.126l-9.771 68.286-27.704-63.404zM177.396 185.896c-3.189 1.298-7.93 2.613-12.324 2.613-20.084 0-36.367-15.419-36.367-34.438 0-19.02 16.283-34.438 36.367-34.438 4.322 0 9.062 1.053 12.324 2.549v13.166a22.36 22.36 0 0 0-12.324-3.681c-12.398 0-22.451 10.03-22.451 22.403 0 12.372 10.053 22.402 22.451 22.402 4.529 0 8.797-1.338 12.324-3.641v13.065zM56.562 121.883h13.351v65.801l-41.101-37.536v36.096H15.461V120.46l41.101 37.536v-36.113zM114.652 151.85c4.076-3.02 7.625-5.902 7.592-13.503-.045-10.004-8.139-16.526-18.178-16.526H77.573v64.486h29.716c10.525 0 18.533-7.984 18.533-18.476 0-10.49-8.672-15.069-11.17-15.981zm-23.656-20.28l9.924.001c4.646 0 8.412 3.754 8.412 8.385 0 4.63-3.766 8.385-8.412 8.385h-9.924V131.57zm12.082 45.057h-12.08v-18.652l12.081-.055c5.18 0 9.379 4.188 9.379 9.354s-4.2 9.353-9.38 9.353z"
            />
        </g>
    </svg>
</template>

<script>
export default {
    name: 'Nbc'
};
</script>
