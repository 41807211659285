import { PrEvent, PrEventMessageType, PrEventStatus } from '@/types/PrEvent';
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';

@Component({
    computed: {
        ...mapGetters('user', ['isAuthor', 'isEditor', 'isAdmin'])
    }
})
export default class PrEvents extends Vue {
    isAuthor!: boolean;
    isEditor!: boolean;
    isAdmin!: boolean;

    get prEventMessageTypeByUser() {
        return [
            PrEventMessageType.CommentApprove,
            PrEventMessageType.CommentReject,
            PrEventMessageType.Comment,
            PrEventMessageType.CommentContinuation
        ];
    }

    getNewCommentsForLast48Hours(events: PrEvent[] = []) {
        const last48Hours = this.$date().subtract(2, 'days').unix();

        return events.some(event => {
            return (
                event.timestamp >= last48Hours &&
                event.user_id &&
                !event.belongs_to_current_user &&
                this.prEventMessageTypeByUser.includes(event.message_type) &&
                event.status !== PrEventStatus.AwaitingPayment
            );
        });
    }
}
