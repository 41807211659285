import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class InfoFieldMixin extends Vue {
    showInfo(fieldName: string) {
        this.hideAllInfoAlerts();

        const alertDom = document.querySelector(
            `[data-alert="alert-${fieldName}"]`
        );

        if (alertDom) {
            alertDom.classList.contains('d-none') &&
                alertDom.classList.remove('d-none');
        }
    }

    hideInfo(fieldName: string) {
        const alertDom = document.querySelector(
            `[data-alert="alert-${fieldName}"]`
        );

        if (alertDom) {
            !alertDom.classList.contains('d-none') &&
                alertDom.classList.add('d-none');
        }
    }

    showContextSuggestionInfoAlert(fieldName: string) {
        this.hideAllContextSuggestionInfoAlerts();

        const currentContextAlert = document.querySelector(
            `[data-suggestion-for="${fieldName}"]`
        );

        if (currentContextAlert) {
            currentContextAlert.classList.remove('d-none');
        }
    }

    hideAllContextSuggestionInfoAlerts() {
        const contextAlerts = document.querySelectorAll(
            '[data-type="suggestion-alert"]'
        );

        contextAlerts.forEach(suggestionAlert => {
            suggestionAlert.classList.add('d-none');
        });
    }

    hideAllInfoAlerts() {
        const alerts = document.querySelectorAll(
            '[data-type="toggleable-info"]'
        );

        alerts.forEach(function (alert) {
            alert.classList.add('d-none');
        });
    }

    showInfoAndFit(field: string) {
        this.showInfo(field);

        this.fitInfosFor(field);
    }
    /**
     * This code slides down all info-alert below the target field alert
     * so they don't overlap on small screens
     */
    async fitInfosFor(forField: string) {
        const infoForField = this.$el.querySelector(
            `[data-alert="alert-${forField}"]`
        );

        if (infoForField) {
            const visibleInfos = [
                ...this.$el.querySelectorAll('[data-type]')
            ].filter(node => {
                if (node !== infoForField) {
                    const { width, height } = node.getBoundingClientRect();

                    return Boolean(width && height);
                }

                return false;
            });

            if (visibleInfos.length) {
                const infosTofit = [infoForField, ...visibleInfos];

                for (let pointer = 0; pointer < infosTofit.length; pointer++) {
                    if (infosTofit[pointer] && infosTofit[pointer + 1]) {
                        await this.fitInfos(
                            infosTofit[pointer],
                            infosTofit[pointer + 1]
                        );
                    }
                }
            }
        }
    }

    async fitInfos(target: Element, element: Element) {
        const gap = 16; // px between elements
        const transitionDelay = 300; // default transition delay for Vuetify

        if (target && element) {
            const targetRect = target.getBoundingClientRect();
            const elementRect = element.getBoundingClientRect();

            const lowestEdgePosition = targetRect.top + targetRect.height + gap;
            const appliedTop = parseFloat(
                window.getComputedStyle(element, null).getPropertyValue('top')
            );
            const zeroTop = elementRect.top - appliedTop;
            const desiredTop = lowestEdgePosition - zeroTop;
            // check if we have overlapping
            if (lowestEdgePosition > zeroTop) {
                if (Math.ceil(appliedTop) !== Math.ceil(desiredTop)) {
                    await Promise.race([
                        new Promise(resolve => {
                            element.addEventListener('transitionend', resolve);

                            (element as HTMLDivElement).style.top =
                                `${desiredTop}px`;
                        }),
                        new Promise(resolve =>
                            setTimeout(resolve, transitionDelay)
                        )
                    ]);
                }
            }

            if (lowestEdgePosition <= zeroTop) {
                await Promise.race([
                    new Promise(resolve => {
                        element.addEventListener('transitionend', resolve);

                        if (
                            lowestEdgePosition === zeroTop ||
                            lowestEdgePosition + gap < zeroTop
                        ) {
                            (element as HTMLDivElement).style.top = `0px`;
                        } else {
                            (element as HTMLDivElement).style.top = `${gap}px`;
                        }
                    }),
                    new Promise(resolve => setTimeout(resolve, transitionDelay))
                ]);
            }
        }
    }
}
