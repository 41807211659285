import Component from 'vue-class-component';
import { Endpoint } from '@/mixins';

@Component
export default class List extends Endpoint {
    get queryParams(): Record<string, string | number | boolean> {
        return {};
    }

    get sourceUrl() {
        return [this.endpoint, this.query].filter(Boolean).join('?');
    }

    get query() {
        return Object.entries(this.queryParams)
            .map(([key, value]) => {
                if (typeof this.$route.query[key] !== 'undefined') {
                    value = String(this.$route.query[key]);
                }

                if (value) {
                    return `${key}=${encodeURIComponent(value)}`;
                }

                return false;
            })
            .filter(Boolean)
            .join('&');
    }

    onCreated() {
        this.$watch(
            () => this.$route.query,
            () => {
                this.update(true);
            }
        );
    }
}
