import Vue from 'vue';
import { Module } from 'vuex';
import { uuid } from '@/utils/helpers';

import { RootState } from '@/store/State';

export type CacheKeyState = {
    keys: Record<string, string>;
};

const defaultState: CacheKeyState = {
    keys: {}
};

const CacheKey: Module<CacheKeyState, RootState> = {
    namespaced: true,

    state: { ...defaultState },

    getters: {
        get: state => (key: string) => {
            return state.keys[key];
        }
    },

    actions: {
        generate({ commit }, key) {
            const value = uuid();

            commit('set', { key, value });

            return value;
        },

        reset({ dispatch }, key) {
            dispatch('generate', key);
        }
    },

    mutations: {
        set(state, { key, value }: { key: string; value: string }) {
            Vue.set(state.keys, key, value);
        }
    }
};

export default CacheKey;
