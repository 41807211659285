import { Module } from 'vuex';

import { RootState } from '@/store/State';

export type NotificationState = {
    show: boolean;
    timeout: number;
    message: string;
    color: string;
};

const defaultNotificationState: NotificationState = {
    show: false,
    timeout: 4000,
    message: '',
    color: 'default'
};

const Notification: Module<NotificationState, RootState> = {
    namespaced: true,

    state: { ...defaultNotificationState },

    getters: {
        getNotification(state) {
            return state;
        }
    },

    actions: {
        error({ commit }, message) {
            commit('showNotification', { message, color: 'error' });
        },

        success({ commit }, message) {
            commit('showNotification', { message, color: 'success' });
        },

        info({ commit }, message) {
            commit('showNotification', { message, color: 'white' });
        },

        warn({ commit }, message) {
            commit('showNotification', { message, color: 'orange lighten-5' });
        },

        reset({ commit }) {
            commit('resetNotification');
        },

        show({ commit }, message) {
            commit('showNotification', { message, color: 'default' });
        }
    },

    mutations: {
        showNotification(state, { message, color }: NotificationState) {
            state.message = message;
            state.color = color;
            state.show = true;
        },

        resetNotification(state) {
            state.color = defaultNotificationState.color;
            state.message = defaultNotificationState.message;
            state.show = defaultNotificationState.show;
        }
    }
};

export default Notification;
