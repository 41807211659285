import { extend } from 'vee-validate';
import { wordCount } from '@/utils/helpers';

type WordsCoundValidationParams = {
    min?: number;
    max?: number;
    _value_?: string;
};

extend('words', {
    params: ['min', 'max'],
    validate(value, params) {
        const { min, max } = params as WordsCoundValidationParams;

        const minWords = Number(min);
        const maxWords = Number(max);

        if (!value) {
            if (min) {
                return false;
            }
        } else {
            const words = wordCount(value);

            if (minWords) {
                if (maxWords) {
                    return words >= minWords && words <= maxWords;
                } else {
                    return words >= minWords;
                }
            } else {
                if (maxWords) {
                    return words <= maxWords;
                }
            }
        }

        return true;
    },
    message: (fieldName, placeholders) => {
        const { min, max, _value_ } =
            placeholders as WordsCoundValidationParams;

        const minWords = Number(min);
        const maxWords = Number(max);

        const words = wordCount(_value_);

        if (minWords) {
            if (maxWords) {
                return `${fieldName} must be between ${minWords} and ${maxWords} words at most. It is ${words} words now.`;
            } else {
                return `${fieldName} must be more than ${minWords} words. It is ${words} words now.`;
            }
        } else {
            if (maxWords) {
                return `${fieldName} must be ${maxWords} words at most. It is ${words} words now.`;
            }
        }

        return `${fieldName} has ${words} words`;
    }
});
