import { AbilityBuilder } from '@casl/ability';

import { User } from './Subject';
import { DefinedRole } from './Role';
import { AppAbility } from './Ability';

type DefinePermissions = (
    user: User,
    builder: AbilityBuilder<AppAbility>
) => void;

// This is where we define which Role can do what Action to what Subject
export const RolePermissions: Record<DefinedRole, DefinePermissions> = {
    Visitor() {
        // can do nothing special, default permissions to everyone
    },
    Admin(user, { can, cannot }) {
        can('manage', 'all');
        // cannot declarations should come last, otherwise they will be overridden by can
        cannot('manage', 'WhiteLabel');
        cannot('manage', 'Clients');
        cannot('view', 'AuthorSlides');
        cannot('view', 'AuthorTrainingLessonLinks');
        cannot('view', 'AuthorAssigmentsList');
    },
    Author(user, { can }) {
        can('view', 'Welcome');
        can('view', 'AuthorRequests');
        can('view', 'AuthorAssigmentsList');
        can('view', 'Training');
        can('view', 'AuthorSlides');
        can('view', 'AuthorTrainingLessonLinks');
        can('manage', 'AuthorRequestInternalDetails');
    },
    Editor(user, { can }) {
        can('view', 'Welcome');
        can('view', 'AuthorRequests');
        can('view', 'AuthorAssigmentsList');
        can('view', 'Training');
        can('view', 'AuthorSlides');
        can('view', 'AuthorTrainingLessonLinks');
        can('manage', 'AuthorRequestInternalDetails');
        can('manage', 'Videos');
        can('manage', 'BlogPosts');
        can('manage', 'NewsArticles');
    },
    Customer(user, { can }) {
        can('view', 'Welcome');
        can('view', 'Announcements');
        can('view', 'AuthorRequests');
        can('update', 'User', { id: user.id });
        can('view', 'Training');
        can('manage', 'Companies');
    },
    Reseller(user, { can }) {
        can('view', 'Welcome');
        can('view', 'Announcements');
        can('view', 'AuthorRequests');
        can('view', 'Training');
        can('create', 'Client');
        can('manage', 'Client', { reseller_id: user.id });
        can('update', 'User', { id: user.id });
        can('manage', 'WhiteLabel');
        can('manage', 'Clients');
        can('manage', 'Companies');
    },
    TrainingCourseBuyer(user, { can, cannot }) {
        can('view', 'Training');
        cannot('view', 'Welcome');
    },
    Affiliate(user, { can }) {
        can('view', 'Welcome');
        can('view', 'Announcements');
        can('view', 'Training');
        can('manage', 'Companies');
    }
};
