import { extend } from 'vee-validate';

type UrlValidationParams = {
    options: string;
};

const VALID_PROTOCOLS = ['http:', 'https:'];

function isValidUrl(url: URL) {
    return Boolean(url) && VALID_PROTOCOLS.includes(url.protocol);
}

function isSecure(url: URL) {
    return Boolean(url) && url.protocol === 'https:';
}

extend('url', {
    params: ['options'],
    validate(value, params) {
        if (!value) {
            return true;
        }

        if (value.includes(' ')) {
            return false;
        }

        const { options = '' } = params as UrlValidationParams;

        try {
            const url = new URL(value);

            return (
                isValidUrl(url) &&
                (options.includes('secure') ? isSecure(url) : true)
            );
        } catch {
            return false;
        }
    },
    message: '{_value_} is not a valid URL'
});
