import type { ValidationRule } from 'vee-validate/dist/types/types';

type Validator = (value: never) => boolean | string | Promise<boolean | string>;

const INSTANCES: Map<number | string, Validator> = new Map();

export function setValidator(id: number | string, validator: Validator) {
    INSTANCES.set(String(id), validator);
}

export function unsetValidator(id: number | string) {
    INSTANCES.delete(id);
}

export const validator: ValidationRule = {
    validate(value, params) {
        if (Array.isArray(params)) {
            const validator = INSTANCES.get(String(params[0]));

            if (validator && typeof validator === 'function') {
                return validator(value as never);
            }
        }

        return true;
    }
};

/**
  When using this kind of validator
  don't forget to register it

    created() {
        setValidator(this.id, () => true);
    }

    beforeDestroy() {
        unsetValidator(this.id);
    }

 */
