// https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
// Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
const convertRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;

export function expandHex(hex = '') {
    return hex.replace(shorthandRegex, (_m, r, g, b) => r + r + g + g + b + b);
}

export default function hex2rgb(hex = '') {
    const result = convertRegex.exec(expandHex(hex));

    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16)
          }
        : {
              r: 0,
              g: 0,
              b: 0
          };
}
