import { Loader, LoaderOptions } from 'google-maps';
import type { FullAddress } from '.';

import type { google } from 'google-maps/lib/types/types';

import Address from './Address';

export default class GoogleMaps {
    google: Promise<google> | null = null;
    loader: Loader;

    geocoder: InstanceType<typeof google.maps.Geocoder> | null = null;

    defaultOptions: LoaderOptions = {
        language: 'en'
    };

    constructor(key = '', options: LoaderOptions = {}) {
        this.loader = new Loader(key, {
            ...this.defaultOptions,
            ...options
        });
    }

    async getGoogle() {
        if (this.google === null) {
            this.google = this.loader.load();
        }

        return Promise.resolve(this.google);
    }

    async geocode(address: FullAddress) {
        if (!this.geocoder) {
            const google = await this.getGoogle();

            this.geocoder = new google.maps.Geocoder();
        }

        return this.geocoder.geocode({
            address: Address.build(address)
        });
    }

    suggest(address: FullAddress, results: google.maps.GeocoderResult[]) {
        return Address.suggest(address, results);
    }
}
