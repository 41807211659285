import { extend } from 'vee-validate';
import { cleanCharacterCount } from '@/utils/helpers/characterCount';

type CharactersCoundValidationParams = {
    min?: number;
    max?: number;
    _value_?: string;
};

extend('characters', {
    params: ['min', 'max'],
    validate(value, params) {
        const { min, max } = params as CharactersCoundValidationParams;
        const minCharacters = Number(min);
        const maxCharacters = Number(max);
        const characters = cleanCharacterCount(value);

        // Handle empty value case
        if (!value) {
            return !minCharacters; // If minCharacters is set, return false, else true
        }

        // Validate character counts
        const isValidMin = minCharacters ? characters >= minCharacters : true;
        const isValidMax = maxCharacters ? characters <= maxCharacters : true;

        return isValidMin && isValidMax;
    },
    message: (fieldName, placeholders) => {
        const { min, max, _value_ } =
            placeholders as CharactersCoundValidationParams;

        const minCharacters = Number(min);
        const maxCharacters = Number(max);
        const characters = cleanCharacterCount(_value_);

        if (minCharacters || maxCharacters) {
            if (minCharacters && maxCharacters) {
                return `${fieldName} must be between ${minCharacters} and ${maxCharacters} characters. It is ${characters} characters now.`;
            }
            if (minCharacters) {
                return `${fieldName} must be more than ${minCharacters} characters. It is ${characters} characters now.`;
            }
            if (maxCharacters) {
                return `${fieldName} must be at most ${maxCharacters} characters. It is ${characters} characters now.`;
            }
        }

        return `${fieldName} has ${characters} characters.`;
    }
});
