<template>
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10 0.25C4.62354 0.25 0.25 4.62354 0.25 10C0.25 15.3765 4.62354 19.75 10 19.75C15.3765 19.75 19.75 15.3765 19.75 10C19.75 4.62354 15.3765 0.25 10 0.25ZM10 1.875C14.4973 1.875 18.125 5.50269 18.125 10C18.125 14.4973 14.4973 18.125 10 18.125C5.50269 18.125 1.875 14.4973 1.875 10C1.875 5.50269 5.50269 1.875 10 1.875ZM7.15625 6.75C6.03271 6.75 5.125 7.65771 5.125 8.78125C5.125 9.20972 5.25195 9.62231 5.48047 9.94922C5.48047 9.95239 5.50586 9.94922 5.50586 9.94922L10 15.0781L14.4941 9.94922L14.5195 9.92383C14.748 9.59692 14.875 9.20972 14.875 8.78125C14.875 7.65771 13.9673 6.75 12.8437 6.75C11.4092 6.75 11.0664 8.12109 10 8.12109C8.93359 8.12109 8.59082 6.75 7.15625 6.75Z"
            fill="#59748A"
        />
    </svg>
</template>

<script>
export default {
    name: 'Heart'
};
</script>
