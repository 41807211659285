import Vue from 'vue';
import VueRouter, { RouterOptions } from 'vue-router';

import routes from './routes';

import store from '@/store';

Vue.use(VueRouter);

const DEFAULT_TITLE = 'Ampifire';

const routerOptions = <RouterOptions>{
    mode: 'history',
    routes,
    base: '/app',
    scrollBehavior(to, _from, savedPosition) {
        if (to.hash) {
            return { selector: to.hash, behavior: 'smooth' };
        } else if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    }
};

const router = new VueRouter(routerOptions);

router.beforeEach(async (to, from, next) => {
    store.dispatch('loading/show');

    if (to.matched.some(record => record.meta.requiresAuth)) {
        await store.dispatch('user/authorize');

        if (to.name !== 'Login' && !store.getters['user/hasActiveSession']) {
            next({
                name: 'Login',
                params: { nextUrl: to.fullPath }
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

router.afterEach((to, from) => {
    store.dispatch('loading/hide');

    Vue.nextTick(() => {
        let title = '';

        if (to.meta?.title) {
            if (typeof to.meta?.title === 'function') {
                title = to.meta.title(to, from);
            } else {
                title = to.meta.title;
            }
        }

        document.title =
            title || store.state.user?.businessName || DEFAULT_TITLE;
    });
});

export default router;
