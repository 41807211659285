import { Module } from 'vuex';

import type { AuthorUser } from '@/types/User';

import { RootState } from '@/store/State';

export type AuthorsState = {
    authors: AuthorUser[];
};

const DefaultAuthorsState: AuthorsState = {
    authors: []
};

function convertAuthors(authors: Record<string, string> | null): AuthorUser[] {
    return authors
        ? Object.entries(authors)
              .map(([id, name]) => ({
                  id: Number(id),
                  name
              }))
              .sort((a, b) => a.name.localeCompare(b.name))
        : [];
}

const Authors: Module<AuthorsState, RootState> = {
    namespaced: true,

    state: { ...DefaultAuthorsState },

    actions: {
        set({ commit }, authors) {
            commit('set', authors);
        }
    },

    mutations: {
        set(state, authors: AuthorUser[] | Record<string, string>) {
            if (Array.isArray(authors)) {
                state.authors = authors;
            } else {
                state.authors = convertAuthors(authors);
            }
        }
    }
};

export default Authors;
