import type { FormMediaResource } from '@/types/Media';
import type { MediaFile } from '@/types/MediaFile';

export default function setMediaResources(
    mediaFiles: MediaFile[] = []
): FormMediaResource[] {
    return mediaFiles.map(mediaFile => ({
        media_file_id: mediaFile.id,
        media_file: mediaFile,
        original_url: mediaFile.original_url,
        featured: false,
        is_used: false
    }));
}
