/* eslint-disable @typescript-eslint/no-explicit-any */
import { AnalyticsProvider } from '@/components/ClientCabin/Analytics/Provider';
import { AnalyticsBrowser } from '@segment/analytics-next';

export default class Segment implements AnalyticsProvider {
    code: string | number | null = null;

    analytics: AnalyticsBrowser | null = null;

    constructor(code: string | number | null) {
        this.code = code;
    }

    load() {
        if (this.code) {
            this.analytics = AnalyticsBrowser.load({
                writeKey: String(this.code)
            });
        }
    }

    track(actionName: string, properties?: any, options?: any) {
        if (this.code) {
            this.analytics?.track(actionName, properties, options);
        }
    }

    trackPage() {
        if (this.code) {
            this.analytics?.page();
        }
    }
}
