<template>
    <svg
        id="Layer_1"
        height="100px"
        width="100px"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 512.001 512.001"
        xml:space="preserve"
    >
        <path
            style="fill: #ffd155"
            d="M245.524,508.61C56.135,376.811,60.841,353.675,24.089,146.648c-1.306-7.358,2.106-14.75,8.552-18.53
	L246.582,2.682c5.746-3.561,13.027-3.582,18.793-0.025L479.36,128.118c6.447,3.779,9.858,11.173,8.552,18.53
	c-36.698,206.777-31.995,230.142-221.569,362.055C260.139,513.107,251.764,513.124,245.524,508.61z"
        />
        <path
            style="fill: #ffbe00"
            d="M479.359,128.118L265.375,2.657c-2.874-1.773-6.125-2.655-9.375-2.655v511.998
	c3.632-0.001,7.255-1.105,10.341-3.296C455.916,376.79,451.213,353.425,487.911,146.648
	C489.218,139.29,485.805,131.897,479.359,128.118z"
        />
        <path
            style="fill: #ffe49c"
            d="M245.799,421.766c-120.552-83.878-122.137-122.956-142.674-238.64
	c-1.306-7.357,2.106-14.751,8.552-18.53l135.289-79.322c5.58-3.271,12.488-3.271,18.068,0l135.289,79.322
	c6.445,3.779,9.858,11.173,8.552,18.53c-20.439,115.149-21.997,154.674-142.674,238.64
	C260.07,426.031,251.93,426.032,245.799,421.766z"
        />
        <g>
            <path
                style="fill: #ffd155"
                d="M400.324,164.596l-135.289-79.32c-2.79-1.636-5.912-2.453-9.034-2.453v342.144
		c3.567,0,7.135-1.067,10.201-3.199c120.678-83.965,122.235-123.49,142.674-238.64C410.182,175.768,406.769,168.375,400.324,164.596
		z"
            />
            <path
                style="fill: #ffd155"
                d="M256,314.331c-36.759,0-66.664-29.905-66.664-66.664s29.905-66.664,66.664-66.664
		s66.664,29.905,66.664,66.664S292.758,314.331,256,314.331z"
            />
        </g>
        <path
            style="fill: #ffbe00"
            d="M256,181.003v133.328c36.759,0,66.664-29.905,66.664-66.664S292.758,181.003,256,181.003z"
        />
    </svg>
</template>

<script>
export default {
    name: 'Secure'
};
</script>
