<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="2 4 31 31">
        <path
            fill="currentColor"
            d="M12.13 35c-1.75 0-3.25-1.1-3.25-3.59 0-3.85 3.32-6.77 4.82-7.6a30.41 30.41 0 0 0 4.28 2 15.67 15.67 0 0 1-7.1-13.28c0-5.2 2.74-7.53 5.21-7.53 2 0 6.69 2.19 6.69 10.57 0 9-4.71 19.43-10.65 19.43Z"
        />
        <path
            fill="currentColor"
            d="M19.7 32.14c0 1.31.46 1.82 1.16 1.82 1.75 0 4.53-4.08 4.43-8.36 2.65-2.8 5.83-7.18 5.83-10.69 0-.85-.25-1.56-1.25-1.56-1.54 0-3.74 2.65-5.13 5.28s-2.61 5.84-4.28 10.12a10.88 10.88 0 0 0-.76 3.39Z"
        />
    </svg>
</template>

<script>
export default {
    name: 'Msn'
};
</script>
