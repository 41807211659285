import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VAppBar,{staticClass:"header",attrs:{"app":"","fixed":"","color":_vm.barBackgroundColor}},[_c('loading'),_c(VContainer,{staticClass:"inner-container fill pa-0"},[_c(VRow,{staticClass:"pa-0 fill",attrs:{"justify":"center"}},[_c(VCol,{staticClass:"py-0 px-md-2",attrs:{"cols":"12","lg":"9","xl":"7"}},[_c(VContainer,{staticClass:"header-container fill",attrs:{"fluid":_vm.$vuetify.breakpoint.mdAndDown}},[_c(VRow,{staticClass:"header-wrapper fill",attrs:{"align":"center","justify":"space-between"}},[_c(VCol,{staticClass:"d-flex align-center logo-container py-0 justify-center",attrs:{"cols":"auto"}},[(_vm.isLogoLoaded)?_c('router-link',{class:{
                                    'text-h6 text-decoration-none': !_vm.hasLogo
                                },attrs:{"to":"/"}},[(_vm.hasLogo)?_c(VImg,{attrs:{"src":_vm.logo.src,"alt":_vm.logo.alt,"position":"top left","max-height":"64","max-width":"180","contain":""}}):_c('div',[_vm._v(" "+_vm._s(_vm.businessName)+" ")])],1):_vm._e()],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c(VCol,{staticClass:"navbar-container fill",attrs:{"cols":"auto"}},[_c(VList,{staticClass:"d-inline-flex navbar-wrapper fill",attrs:{"color":_vm.barBackgroundColor}},_vm._l((_vm.availableTabs),function(tab,i){return _c(VListItem,{key:`tab-${i}`,staticClass:"navbar-item",attrs:{"to":tab.to,"color":"white"}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(tab.title)+" ")])],1)],1)}),1),(_vm.shouldShowBooking)?_c(VBtn,{staticClass:"primary ml-md-4",attrs:{"small":_vm.$vuetify.breakpoint.smAndDown,"to":_vm.bookingUrl}},[_vm._v(" Book Appointment ")]):_vm._e()],1):_c(VCol,{staticClass:"pa-0 d-flex justify-end"},[(_vm.shouldShowBooking)?_c(VBtn,{staticClass:"primary mr-md-4 mt-3",attrs:{"small":_vm.$vuetify.breakpoint.smAndDown,"to":_vm.bookingUrl}},[_vm._v(" Book Appointment ")]):_vm._e(),_c(VMenu,{attrs:{"offset-y":"","bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.hasMenuToRender)?_c(VBtn,_vm._g(_vm._b({attrs:{"fab":"","elevation":"0","color":"white"}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("bars")])],1):_vm._e()]}}])},[_c(VList,{attrs:{"dense":""}},[(_vm.$vuetify.breakpoint.smAndDown)?_c(VListItemGroup,{staticClass:"navbar-menu-container"},_vm._l((_vm.availableTabs),function(tab,i){return _c(VListItem,{key:`tab-${i}`,staticClass:"navbar-item",attrs:{"to":tab.to}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(tab.title)+" ")])],1)],1)}),1):_vm._e()],1)],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }