import { Module } from 'vuex';

import type { ClientUser } from '@/types/User';

import { RootState } from '@/store/State';

export type ClientsState = {
    clients: ClientUser[];
};

const DefaultClientsState: ClientsState = {
    clients: []
};

function convertClients(clients: Record<string, string> | null): ClientUser[] {
    return clients
        ? Object.entries(clients)
              .map(([id, email]) => ({
                  id: Number(id),
                  email
              }))
              .sort((a, b) => a.email.localeCompare(b.email))
        : [];
}

const Clients: Module<ClientsState, RootState> = {
    namespaced: true,

    state: { ...DefaultClientsState },

    actions: {
        set({ commit }, clients) {
            commit('set', clients);
        }
    },

    mutations: {
        set(state, clients: ClientUser[] | Record<string, string>) {
            if (Array.isArray(clients)) {
                state.clients = clients;
            } else {
                state.clients = convertClients(clients);
            }
        }
    }
};

export default Clients;
