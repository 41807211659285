<template>
    <a-form v-slot="{ invalid }" :submit="save">
        <v-navigation-drawer
            v-model="isOpen"
            class="clientcabin clientcabin-setup"
            app
            right
            temporary
            disable-route-watcher
            :width="width"
        >
            <template #prepend>
                <v-container>
                    <v-row>
                        <v-col>
                            <div class="text-h6 px-2 black--text">
                                Setup Your Invisible Sales Machine
                            </div>
                        </v-col>
                        <v-col cols="auto" align="center">
                            <v-btn icon @click="close">
                                <v-icon>close</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <div class="px-6 pb-3 black--text">
                <div class="text-button font-weight-bold">
                    Settings of your sales pages
                </div>

                <div class="d-flex align-center pb-2" style="cursor: pointer">
                    <v-btn-toggle
                        tile
                        color="clientcabin-blue"
                        group
                        class="ml-n2"
                    >
                        <v-btn
                            :class="{
                                'v-btn--active': shouldDisplayGeneralSettings
                            }"
                            @click="showGeneralSettings(true)"
                        >
                            General Settings
                        </v-btn>
                        <v-btn
                            to="/info"
                            :class="{
                                'semi-active': shouldDisplayGeneralSettings
                            }"
                            @click="showGeneralSettings(false)"
                        >
                            Info
                        </v-btn>
                        <v-btn
                            to="/proposal"
                            :class="{
                                'semi-active': shouldDisplayGeneralSettings
                            }"
                            @click="showGeneralSettings(false)"
                        >
                            Sales
                        </v-btn>
                        <v-btn
                            to="/about"
                            :class="{
                                'semi-active': shouldDisplayGeneralSettings
                            }"
                            @click="showGeneralSettings(false)"
                        >
                            About
                        </v-btn>
                        <v-btn
                            v-if="isVIPReseller"
                            to="/leads-from-ads"
                            :class="{
                                'semi-active': shouldDisplayGeneralSettings
                            }"
                            @click="showGeneralSettings(false)"
                        >
                            Leads from Ads
                        </v-btn>
                    </v-btn-toggle>

                    <v-tooltip bottom :close-delay="copied ? copyTimeout : 0">
                        <template #activator="{ on, attrs }">
                            <v-icon
                                v-clipboard:success="copy"
                                v-clipboard:copy="pagePath"
                                v-bind="attrs"
                                small
                                right
                                v-on="on"
                            >
                                copy
                            </v-icon>
                        </template>
                        <span>
                            {{ copyTooltip }}
                        </span>
                    </v-tooltip>
                </div>
                <div v-if="shouldDisplayGeneralSettings">
                    These are settings that we apply to every page.
                </div>
                <div v-if="isInfoPage && !shouldDisplayGeneralSettings">
                    This page is for the initial application. This is followed
                    by a series of questions used to gather information from the
                    client.
                </div>
                <div v-if="isSalesPage && !shouldDisplayGeneralSettings">
                    This page offers the client to checkout and buy one of the
                    products. Please check your
                    <a
                        href="/app/profile/whitelabel"
                        target="_blank"
                        class="d-inline-block"
                    >
                        user panel
                    </a>
                    to set prices and currency for the Media Coverage Campaign
                    (Price to Sell Amp Campaigns).
                </div>
                <div v-if="isAboutPage && !shouldDisplayGeneralSettings">
                    This page is not configurable and includes elements
                    predefined by the Whitelabel domain, such as the business
                    name, logo, and
                    <a
                        href="/app/profile/whitelabel"
                        target="_blank"
                        class="d-inline-block"
                    >
                        other details
                    </a>
                    .
                </div>
                <div v-if="isVIPPage && !shouldDisplayGeneralSettings">
                    This is the VIP Agency Sales page.
                </div>
            </div>
            <v-expansion-panels
                v-if="shouldDisplayGeneralSettings"
                accordion
                focusable
                flat
                tile
            >
                <v-expansion-panel>
                    <v-expansion-panel-header class="text-button py-0">
                        Whitelabel Settings and Pricing
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="black--text">
                        <p class="pt-4">
                            Whitelabel settings and pricing can be changed in
                            your
                            <a
                                href="/app/profile/whitelabel"
                                target="_blank"
                                class="d-inline-block"
                            >
                                Profile
                            </a>
                            .
                        </p>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-header class="text-button py-0">
                        Footer links
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="black--text">
                        <p class="pt-4">
                            Add custom links (up to 10) to footer
                        </p>
                        <div
                            v-for="(link, index) in data.footer.links"
                            :key="index"
                        >
                            <v-row dense>
                                <v-col
                                    class="d-flex align-center text-body-1 font-weight-bold pb-0"
                                >
                                    Link #{{ index + 1 }}
                                </v-col>
                                <v-col class="text-right pb-0">
                                    <v-tooltip bottom>
                                        <template #activator="{ on, attrs }">
                                            <v-btn
                                                icon
                                                small
                                                v-bind="attrs"
                                                v-on="on"
                                                @click="removeLink(index)"
                                            >
                                                <v-icon small>times</v-icon>
                                            </v-btn>
                                        </template>
                                        Delete Link #{{ index + 1 }}
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="12" md="4">
                                    <a-text-input
                                        v-model="link.label"
                                        label="Link Label"
                                        rules="max:100"
                                    />
                                </v-col>
                                <v-col cols="12" md="8">
                                    <a-text-input
                                        v-model="link.url"
                                        label="Link URL"
                                        rules="url|max:255"
                                        autocomplete="off"
                                    />
                                </v-col>
                            </v-row>
                        </div>
                        <v-btn
                            primary
                            :disabled="!canAddMoreLinks"
                            @click="addLink"
                        >
                            Add link
                        </v-btn>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-header class="text-button py-0">
                        Analytics
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="black--text">
                        <p class="pt-4">
                            Add your
                            <b>Google Tag</b>
                            Manager ID (GTM-XXXXXXXX), your
                            <b>Facebook Pixel</b>
                            ID (typically a 15-digit number), or your
                            <b>Segment.io</b>
                            project's write key
                        </p>
                        <a-text-input
                            v-model="data.analytics.google_analytics"
                            label="Google Tag Manager ID"
                            rules="alpha_dash"
                            hint="GTM-"
                        />
                        <a-text-input
                            v-model="data.analytics.fb_pixel"
                            label="Facebook Pixel ID"
                            rules="alpha_dash"
                            hint="Typically a 15-digit number"
                        />
                        <a-text-input
                            v-model="data.analytics.segment_io"
                            label="Segment.io Write Key"
                            rules="alpha_dash"
                        />
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-expansion-panels v-else accordion focusable flat tile>
                <v-expansion-panel v-show="hasHeadlines">
                    <v-expansion-panel-header class="text-button py-0">
                        Headlines
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="black--text">
                        <p class="pt-4">
                            Define custom headline for
                            <b v-show="isInfoPage">Info</b>
                            <b v-show="isSalesPage">Sales</b>
                            page
                        </p>
                        <a-text-input
                            v-show="isInfoPage"
                            v-model="data.custom_headline_short"
                            :placeholder="defaults.custom_headline_short"
                            rules="max:1024"
                            label="Info page Headline"
                            textarea
                            auto-grow
                        />
                        <a-text-input
                            v-show="isSalesPage"
                            v-model="data.custom_headline_long"
                            :placeholder="defaults.custom_headline_long"
                            rules="max:1024"
                            label="Sales page Headline"
                            textarea
                            auto-grow
                        />
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-show="hasVideos">
                    <v-expansion-panel-header class="text-button py-0">
                        Video
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="black--text">
                        <p v-show="isInfoPage" class="pt-4">
                            To display a video on your
                            <b>Info</b>
                            page, simply add the video URL. Once added, the
                            video will automatically appear. By default, no
                            video will be shown unless you manually input a URL.
                        </p>
                        <p v-show="isSalesPage" class="pt-4">
                            To embed a video on your
                            <b>Sales</b>
                            page, add the video URL. If no custom video is
                            added, our top-performing sales video will be
                            displayed by default to enhance engagement.
                        </p>
                        <a-text-input
                            v-show="isInfoPage"
                            v-model="data.video_url"
                            :placeholder="defaults.video_url"
                            label="Video URL"
                            rules="url|max:255"
                            autocomplete="off"
                        />
                        <a-text-input
                            v-show="isSalesPage"
                            v-model="data.sales_video_url"
                            :placeholder="defaults.sales_video_url"
                            label="Video URL"
                            rules="url|max:255"
                            autocomplete="off"
                        />
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-show="isInfoPage">
                    <v-expansion-panel-header class="text-button py-0">
                        Connect form
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="black--text">
                        <p class="pt-4">
                            Add custom Form on
                            <b>Info</b>
                            page. You can pick from one of the providers below.
                        </p>
                        <v-expansion-panels>
                            <v-expansion-panel
                                v-for="provider in providers"
                                :key="provider.id"
                            >
                                <v-expansion-panel-header>
                                    <v-row no-gutters>
                                        <v-col cols="auto" class="pr-4">
                                            <v-tooltip
                                                v-if="
                                                    isDefaultProvider(provider)
                                                "
                                                bottom
                                            >
                                                <template
                                                    #activator="{ on, attrs }"
                                                >
                                                    <v-icon
                                                        color="blue"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    >
                                                        fa-square-check
                                                    </v-icon>
                                                </template>
                                                This Provider is default
                                            </v-tooltip>
                                            <v-tooltip v-else bottom>
                                                <template
                                                    #activator="{ on, attrs }"
                                                >
                                                    <v-icon
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        @click.stop.native="
                                                            setDefaultProvider(
                                                                provider
                                                            )
                                                        "
                                                    >
                                                        far fa-square-check
                                                    </v-icon>
                                                </template>
                                                Set this Provider as default
                                            </v-tooltip>
                                        </v-col>
                                        <v-col align-self="center">
                                            {{ provider.name }}
                                        </v-col>
                                        <v-col
                                            v-if="isDefaultProvider(provider)"
                                            cols="auto"
                                            align-self="end"
                                            class="caption px-4 font-weight-bold"
                                        >
                                            Your Connect Form
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="black--text">
                                    <p class="pt-4">
                                        Learn how to create
                                        {{ provider.name }} form
                                        <a
                                            :href="provider.helpLink"
                                            target="_blank"
                                        >
                                            here
                                        </a>
                                    </p>
                                    <!-- eslint-disable vue/no-v-html -->
                                    <p
                                        v-if="provider.details"
                                        class="text-caption"
                                        v-html="provider.details"
                                    ></p>
                                    <!-- eslint-enable -->
                                    <a-text-input
                                        v-model="data.crm[provider.id]"
                                        :label="provider.name + ' code'"
                                        :spellcheck="false"
                                        :rules="initValidator(provider)"
                                        autocomplete="off"
                                        textarea
                                        rows="10"
                                        class="code"
                                    />
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-show="isVIPPage">
                    <!-- We don't have any expandable panels here, just single input field -->
                    <div class="black--text px-6">
                        <p class="pt-4">
                            Add custom Checkout button URL for VIP Agency Sales
                            page
                        </p>
                        <a-text-input
                            v-model="data.vip_buy_button_url"
                            label="Checkout URL"
                            rules="url|max:1024"
                            autocomplete="off"
                        />
                    </div>
                </v-expansion-panel>
            </v-expansion-panels>

            <template #append>
                <v-container>
                    <v-row>
                        <v-col class="pa-md-6">
                            <v-btn
                                color="clientcabin-blue white--text"
                                :block="$vuetify.breakpoint.smAndDown"
                                :disabled="invalid"
                                type="submit"
                            >
                                Save
                            </v-btn>
                            <v-btn
                                :block="$vuetify.breakpoint.smAndDown"
                                class="ml-md-4"
                                text
                                @click="cancel"
                            >
                                Cancel
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </v-navigation-drawer>
    </a-form>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';
import { InjectReactive, Watch } from '@/utils/decorators';

import { AForm } from '@/components/AForm';
import { ATextInput } from '@/components/AForm/Inputs/ATextInput';

import providers, { ConnectFormProvider } from './ConnectFormProviders';

import {
    setValidator,
    unsetValidator
} from '@/plugins/vee-validate/rules/runtime';

import type { ClientCabin, ClientCabinOptions } from '@/types/ClientCabin';

let validators: string[] = [];

@Component({
    name: 'Setup',
    components: {
        AForm,
        ATextInput
    },
    computed: {
        ...mapGetters('user', ['isVIPReseller'])
    }
})
export default class Setup extends Vue {
    isVIPReseller!: boolean;

    id = 'clientcabin-setup-panel';

    providers = providers;

    copyTimeout = 2000;

    shouldDisplayGeneralSettings = false;

    @InjectReactive({
        from: 'setupEndpoint',
        default() {
            return '/';
        }
    })
    setupEndpoint!: string;

    @InjectReactive({
        from: 'options',
        default() {
            return false;
        }
    })
    options!: Partial<ClientCabin> | null;

    @Watch('options')
    onOptionsUpdate() {
        this.readOptions();
    }

    @InjectReactive({
        from: 'defaults'
    })
    defaults!: ClientCabinOptions;

    @InjectReactive({
        from: 'setMachineOptions',
        default() {
            return () => {};
        }
    })
    setMachineOptions!: (options: ClientCabinOptions) => void;

    get isOpen() {
        return this.$store?.getters['modal/open'](this.id);
    }

    set isOpen(isOpen: boolean) {
        if (!isOpen) {
            this.$store.dispatch('modal/close', this.id);
        }
    }

    get width() {
        return this.$vuetify.breakpoint.smAndDown
            ? '100%'
            : this.$vuetify.breakpoint.xl
              ? '30%'
              : this.$vuetify.breakpoint.md
                ? '50%'
                : '40%';
    }

    get isInfoPage() {
        return this.$route.path === '/info';
    }

    get isSalesPage() {
        return this.$route.path === '/proposal';
    }

    get isAboutPage() {
        return this.$route.path === '/about';
    }

    get isVIPPage() {
        return this.$route.path === '/leads-from-ads';
    }

    get hasHeadlines() {
        return this.isInfoPage || this.isSalesPage;
    }

    get hasVideos() {
        return this.isInfoPage || this.isSalesPage;
    }

    get pagePath() {
        return (
            window.location.origin +
            this.$router.options.base +
            this.$route.path
        );
    }

    get copyTooltip() {
        return this.copied ? 'Link copied' : 'Click to copy link of this page';
    }

    get canAddMoreLinks() {
        return this.data.footer.links.length < 10;
    }

    showGeneralSettings(show = true) {
        this.shouldDisplayGeneralSettings = show;
    }

    data: ClientCabinOptions = {
        custom_headline_short: '',
        custom_headline_long: '',
        video_url: '',
        sales_video_url: '',
        vip_buy_button_url: '',
        footer: {
            links: []
        },
        analytics: {
            google_analytics: '',
            fb_pixel: '',
            segment_io: ''
        },
        crm: {
            google: '',
            hubspot: '',
            getresponse: ''
        },
        default_crm: 'getresponse'
    };

    copied = false;

    mounted() {
        this.readOptions();
    }

    save() {
        this.setOptions();

        this.saveOptions();

        this.close();
    }

    cancel() {
        this.close();

        this.$nextTick(() => {
            this.readOptions();
        });
    }

    close() {
        this.$store.dispatch('modal/close', this.id);
    }

    readOptions() {
        const { options } = this.options || {};

        this.data.custom_headline_short = options?.custom_headline_short || '';

        this.data.custom_headline_long = options?.custom_headline_long || '';

        this.data.video_url = options?.video_url || '';

        this.data.sales_video_url = options?.sales_video_url || '';

        this.data.vip_buy_button_url = options?.vip_buy_button_url || '';

        if (options) {
            this.data.footer = {
                links: [...options.footer.links]
            };

            this.convertOldFooterLink(
                options.footer.link_text,
                options.footer.link_url
            );

            this.data.analytics = {
                google_analytics: options.analytics.google_analytics,
                fb_pixel: options.analytics.fb_pixel,
                segment_io: options.analytics.segment_io
            };

            this.data.crm = {
                google: options.crm?.google || '',
                hubspot: options.crm?.hubspot || '',
                getresponse: options.crm?.getresponse || ''
            };

            this.data.default_crm = options.default_crm || 'google';
        }
    }

    setOptions() {
        this.setMachineOptions(this.data);
    }

    saveOptions() {
        this.$http.post(this.setupEndpoint, this.data);
    }

    isDefaultProvider(provider: ConnectFormProvider) {
        return this.data.default_crm === provider.id;
    }

    setDefaultProvider(provider: ConnectFormProvider) {
        this.data.default_crm = provider.id;
    }

    copy() {
        this.copied = true;

        setTimeout(() => {
            this.copied = false;
        }, this.copyTimeout);
    }

    initValidator(provider: ConnectFormProvider) {
        setValidator(provider.id, provider.validator);

        validators.push(provider.id);

        return `crm:${provider.id}`;
    }

    beforeDestroy() {
        validators.forEach(id => {
            unsetValidator(id);
        });

        validators = [];
    }

    convertOldFooterLink(text = '', url = '') {
        if (!this.data.footer.links.length && (text || url)) {
            this.data.footer.links.push({
                label: text,
                url: url
            });
        }
    }

    addLink() {
        this.data.footer.links.push({ label: '', url: '' });
    }

    removeLink(index: number) {
        this.data.footer.links.splice(index, 1);
    }
}
</script>

<style lang="scss" scoped>
.clientcabin-setup::v-deep {
    .v-expansion-panel[aria-expanded='false'] {
        border-top: 1px solid $mercury-solid;
    }
    .v-expansion-panel-header--active {
        color: $clientcabin-blue;
    }

    .v-expansion-panel-header__icon > .v-icon {
        font-size: 16px;
        width: 16px;
        height: 16px;

        .v-icon__component {
            width: 16px;
            height: 16px;
        }
    }

    .a-textarea.code textarea {
        font-family: monospace;
        font-size: 85%;
        line-height: 1.3em;
    }

    .v-btn--active.semi-active {
        color: grey;
    }
}
</style>
