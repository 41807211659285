/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentOptions } from 'vue';
import { createDecorator } from 'vue-class-component';

import type { InjectKey } from 'vue/types/options';

interface ProvideObj {
    managedReactive: { [k: string]: any };
}

type ProvideFunc = (object | (() => object)) & ProvideObj;

const reactiveInjectKey = '__reactiveInject__';

function needToProduceProvide(original: any) {
    return typeof original !== 'function' || !original.managedReactive;
}

function inheritInjected(componentOptions: ComponentOptions<Vue>) {
    // inject parent reactive services (if any)
    if (!Array.isArray(componentOptions.inject)) {
        componentOptions.inject = componentOptions.inject || {};

        componentOptions.inject[reactiveInjectKey] = {
            from: reactiveInjectKey,
            default() {
                return {};
            }
        };
    }
}

function produceProvide(original: any) {
    const provide: ProvideFunc = function (this: any) {
        let provided =
            typeof original === 'function' ? original.call(this) : original;

        provided = Object.create(provided || null);
        // set reactive services (propagates previous services if necessary)
        provided[reactiveInjectKey] = Object.create(
            this[reactiveInjectKey] || null
        );

        for (const property in provide.managedReactive) {
            // default provide
            provided[provide.managedReactive[property]] = this[property];
            // reactive provide
            Object.defineProperty(
                provided[reactiveInjectKey],
                provide.managedReactive[property],
                {
                    enumerable: true,
                    configurable: true,
                    get: () => this[property]
                }
            );
        }
        return provided;
    };

    provide.managedReactive = Object.create(null);

    return provide;
}
/**
 * decorator of a reactive provide
 * @param as Name to be provided as
 * @return PropertyDecorator | void
 */
export function ProvideReactive(as?: any) {
    return createDecorator((componentOptions, property) => {
        let provide = componentOptions.provide;

        inheritInjected(componentOptions);

        if (needToProduceProvide(provide)) {
            provide = componentOptions.provide = produceProvide(provide);
        }

        if (provide) {
            (provide as ProvideFunc).managedReactive[property] = as || property;
        }
    });
}
/**
 * decorator of a reactive inject
 * @param from key
 * @return PropertyDecorator
 */
export function InjectReactive<T>(
    options?:
        | {
              from?: InjectKey;
              default?: (this: T) => any;
          }
        | {
              from?: InjectKey;
              default?: any;
          }
        | InjectKey
) {
    return createDecorator((componentOptions, property) => {
        if (typeof componentOptions.inject === 'undefined') {
            componentOptions.inject = {};
        }

        if (!Array.isArray(componentOptions.inject)) {
            const fromProperty = options
                ? (options as any).from || options
                : property;
            const defaultVal =
                (Boolean(options) && (options as any).default) || void 0;

            if (typeof componentOptions.computed === 'undefined') {
                componentOptions.computed = {};
            }

            componentOptions.computed[property] = function () {
                const injected = (this as any)[reactiveInjectKey];

                return typeof injected[fromProperty] === 'undefined'
                    ? typeof defaultVal === 'function'
                        ? defaultVal.call(this)
                        : defaultVal
                    : injected[fromProperty];
            };

            componentOptions.inject[reactiveInjectKey] = reactiveInjectKey;
        }
    });
}
