import type { FullAddress, AddressComponents, Suggestion } from '.';

export default {
    build(address: FullAddress) {
        return [
            address.address,
            address.city,
            [address.state, address.postal_code].filter(Boolean).join(' '),
            address.country
        ]
            .filter(Boolean)
            .join(', ');
    },

    suggest(address: FullAddress, results: google.maps.GeocoderResult[]) {
        const firstMatch = results[0];

        if (firstMatch) {
            const components = this.collectComponents(firstMatch);

            const suggested: Suggestion = {};

            this.suggestAddress(address, components, suggested);
            this.suggestCity(address, components, suggested);
            this.suggestState(address, components, suggested);
            this.suggestPostalCode(address, components, suggested);
            this.suggestCountry(address, components, suggested);

            if (Object.keys(suggested).length) {
                suggested.formatted_address = firstMatch.formatted_address;

                return suggested;
            }
        }

        return null;
    },

    collectComponents(result: google.maps.GeocoderResult): AddressComponents {
        const long: Record<string, string> = {};
        const short: Record<string, string> = {};

        result.address_components.forEach(component => {
            long[component.types[0]] = component.long_name;
            short[component.types[0]] = component.short_name;
        });

        return {
            long,
            short
        };
    },

    suggestAddress(
        address: FullAddress,
        components: AddressComponents,
        suggested: Suggestion
    ) {
        const { long, short } = components;
        const suggestedLongAddress = [long.street_number, long.route]
            .filter(Boolean)
            .join(' ');

        const suggestedShortAddress = [short.street_number, short.route]
            .filter(Boolean)
            .join(' ');

        if (!address.address) {
            // add partial result even if address1 is not filled
            suggested.address = suggestedLongAddress;
        } else {
            if (
                !address.address.includes(suggestedLongAddress) &&
                !address.address.includes(suggestedShortAddress)
            ) {
                suggested.address = suggestedLongAddress;
            }
        }

        if (short.subpremise && address.address.includes(short.subpremise)) {
            suggested.address = suggested.address
                ? `${suggested.address} #${short.subpremise}`
                : address.address;
        }
    },

    suggestCity(
        address: FullAddress,
        components: AddressComponents,
        suggested: Suggestion
    ) {
        const { long, short } = components;
        if (
            ![
                long.locality,
                short.locality,
                long.postal_town,
                short.postal_town
            ]
                .filter(Boolean)
                .includes(address.city)
        ) {
            suggested.city = long.locality || long.postal_town || '';
        }
    },

    suggestState(
        address: FullAddress,
        components: AddressComponents,
        suggested: Suggestion
    ) {
        const { long, short } = components;
        // only set administrative_area_level_1 if address.state != level_1 or level_2.
        // if either is true, don't overwrite.
        if (
            ![
                long.administrative_area_level_1,
                short.administrative_area_level_1
            ]
                .filter(Boolean)
                .includes(address.state) &&
            ![
                long.administrative_area_level_2,
                short.administrative_area_level_2
            ]
                .filter(Boolean)
                .includes(address.state)
        ) {
            suggested.state = long.administrative_area_level_1;
        }
    },

    suggestPostalCode(
        address: FullAddress,
        components: AddressComponents,
        suggested: Suggestion
    ) {
        const { long } = components;

        if (address.postal_code !== long.postal_code) {
            suggested.postal_code = long.postal_code;
        }
    },

    suggestCountry(
        address: FullAddress,
        components: AddressComponents,
        suggested: Suggestion
    ) {
        const { long } = components;

        if (address.country !== long.country) {
            suggested.country = long.country;
        }
    }
};
