import type { IconDefinition } from '@fortawesome/fontawesome-common-types';

export default {
    prefix: 'fas',
    iconName: 'canvas-vertical',
    icon: [
        18,
        18,
        [],
        '',
        'M17.5979 17.1932C17.5094 17.3636 17.3869 17.5041 17.2417 17.6014C17.0966 17.6988 16.9335 17.7499 16.7676 17.75H10.8748C10.6144 17.75 10.3646 17.6243 10.1804 17.4006C9.99619 17.1768 9.89271 16.8734 9.89271 16.557V1.44519C9.89231 1.17401 9.96797 0.910758 10.1072 0.698811C10.2465 0.486865 10.441 0.338872 10.6588 0.279222C10.8766 0.219572 11.1046 0.251823 11.3052 0.370662C11.5059 0.4895 11.6673 0.687834 11.7627 0.932979L17.6555 16.0448C17.7264 16.2266 17.7583 16.4272 17.7482 16.628C17.7381 16.8288 17.6864 17.0232 17.5979 17.1932ZM11.857 6.74863V15.3623H15.2159L11.857 6.74863ZM0.904732 17.7484C0.793078 17.7484 0.68328 17.7137 0.585772 17.6476C0.488264 17.5816 0.406287 17.4863 0.34763 17.3709C0.288973 17.2555 0.255586 17.1238 0.250642 16.9883C0.245698 16.8528 0.269361 16.718 0.319382 16.5967L6.86692 0.6896C6.93299 0.529485 7.0416 0.401141 7.17519 0.325347C7.30878 0.249554 7.45951 0.230748 7.60299 0.271974C7.74647 0.3132 7.8743 0.412045 7.96577 0.552506C8.05725 0.692966 8.10702 0.86682 8.10703 1.04592V16.9531C8.10703 17.164 8.03805 17.3663 7.91526 17.5155C7.79247 17.6646 7.62593 17.7484 7.45228 17.7484H0.904732Z'
    ]
} as unknown as IconDefinition;
