<template>
    <v-snackbar v-model="show" :timeout="timeout" :color="color">
        <!-- eslint-disable vue/no-v-html -->
        <span
            class="d-flex align-center justify-center"
            :class="{
                'secondary--text': useDarkColor,
                'orange--text': isWarning
            }"
            v-html="message"
        ></span>
        <!-- eslint-enable -->
    </v-snackbar>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapState } from 'vuex';

@Component({
    computed: {
        ...mapState('notification', ['color', 'timeout', 'message'])
    }
})
export default class ToastNotification extends Vue {
    message!: string;
    timeout!: number;
    color!: string;

    get show() {
        return this.$store.state.notification.show;
    }

    set show(_prev) {
        this.$store.dispatch('notification/reset');
    }

    get useDarkColor() {
        return ['white'].includes(this.color);
    }

    get isWarning() {
        return this.color.includes('orange');
    }
}
</script>
