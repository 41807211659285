import Vue from 'vue';
import Component from 'vue-class-component';

import { validate } from 'vee-validate';
import type { ValidationResult } from 'vee-validate/dist/types/types';

import type { VideoBox } from '@/types/Video';

@Component
export default class MediaModuleValidate extends Vue {
    linkValidation = true;
    linkEntityLabel = 'slide';

    async validate(boxes: VideoBox[]) {
        const results = await Promise.all(
            boxes.reduce((acc: Promise<ValidationResult>[], box: VideoBox) => {
                if (box.type === 'video_text_box') {
                    acc.push(this.validateTextBox(box));
                }

                if (box.type === 'video_media_box') {
                    acc.push(this.validateMediaBox(box));
                }

                return acc;
            }, [])
        );

        const errors = results.filter(result => !result.valid);

        return errors.length
            ? JSON.stringify(errors.map(({ errors }) => errors[0]))
            : true;
    }

    async validateTextBox(box?: VideoBox | null) {
        const result = await validate(
            box?.video_text_box?.video_caption.text,
            `required|max:${box?.video_text_box?.max_length}`
        );

        if (!result.valid && box) {
            if ('required' in result.failedRules) {
                result.errors[0] = `${box.description}${this.getSlideLink(
                    box
                )} is required`;
            } else {
                result.errors[0] = `${box.description}${this.getSlideLink(
                    box
                )} must be fewer than ${
                    box.video_text_box?.max_length
                } characters. Current: ${
                    box.video_text_box?.video_caption.text
                        ? box.video_text_box.video_caption.text.length
                        : '0'
                }`;
            }
        }

        return result;
    }

    async validateMediaBox(box?: VideoBox | null) {
        const result = await validate(
            box?.video_media_box?.media_resource.media_file_id,
            'required'
        );

        if (!result.valid && box) {
            if ('required' in result.failedRules) {
                result.errors[0] = `${box.description}${this.getSlideLink(
                    box
                )} requires an image. Please upload one.`;
            }
        }

        return result;
    }

    getSlideLink(box: VideoBox) {
        return this.linkValidation
            ? ` on <a data-slide="${box?.ui_group + 1}">${
                  this.linkEntityLabel
              } #${box.ui_group + 1}</a>`
            : '';
    }
}
