<template>
    <ampifire-layout>
        <router-view />
    </ampifire-layout>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import AmpifireLayout from '@/layouts/Ampifire/AmpifireLayout.vue';

@Component({
    components: {
        AmpifireLayout
    }
})
export default class Ampifire extends Vue {}
</script>
