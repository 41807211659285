import { library } from '@fortawesome/fontawesome-svg-core';

import {
    faCircle,
    faClock,
    faCopy,
    faDotCircle,
    faSquare,
    faSquareCheck,
    faCircleQuestion,
    faRectangleXmark,
    faCircleCheck
} from '@fortawesome/free-regular-svg-icons';

library.add(
    faSquare,
    faSquareCheck,
    faCircle,
    faDotCircle,
    faClock,
    faCopy,
    faCircleQuestion,
    faRectangleXmark,
    faCircleCheck
);
